/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../Include/Footer";
import Sidebar from "../../Include/Sidebar";
import { AccountContext } from "../../context/AccountsContext";
import { CustomerContext } from "../../context/CustomerContext";
import AuthContext from "../../context/AuthContext";

function AccountSearch() {
  const location = useLocation();
  const { accounts, customers, customersMutuelle } = location.state || {
    accounts: [],
    customers: [],
    customersMutuelle: [],
  };

  const [accountType, setAccountType] = useState("");
  const [duree, setDuree] = useState("N/A");
  const [sol, setSol] = useState("");
  const [nb_main, setNb_main] = useState("");
  const [mutuelle, setMutuelle] = useState("");
  const [duree_m, setDuree_m] = useState("");
  const { createAccount,getAccountByCode } = useContext(AccountContext);
  const { createCustomerMutuelle, createCustomer } =
    useContext(CustomerContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    sexe: "",
    date_naissance: "",
    lieu_naissance: "",
    email: "",
    tel: "",
    type:"",
    adresse: "",
    statut: "",
    surnom: "",
    image: null,
    devise: "",
    duree: "",
    sol: "",
    nb_main: "",
    mutuelle: "",
    duree_m: "",
  });
 const [initialAccountType, setInitialAccountType] = useState("");
  const [isTypeModified, setIsTypeModified] = useState(false);
  const [initialType, setInitialType] = useState('');  // Stocke la valeur initiale de `type`
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsTypeModified(accountType !== initialAccountType);
  }, [accountType, initialAccountType]);

//   const handleAccountTypeChange = (e) => {
//     const newType = e.target.value;
//     setAccountType(newType);
//   };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlegetAccountByCode = async (code) => {
    try {
      // Remplacez par votre requête pour récupérer les informations du compte par le code

      let formData = new FormData()
      formData.append("code", code)
      const data = await getAccountByCode(formData);
    //   const data = await response.json();

      setFormData({
        ...formData,
        nom: data?.account_info.nom,
        prenom: data?.account_info.prenom,
        sexe: data?.account_info.sexe,
        date_naissance: data?.account_info.date_naissance,
        lieu_naissance: data?.account_info.lieu_naissance,
        email: data?.account_info.email,
        tel: data?.account_info.tel,
        type: data?.account_info.type,
        adresse: data?.account_info.adresse,
        statut: data?.account_info.statut,
        surnom: data?.account_info.surnom,
        devise: data?.account_info.devise,
        duree: data?.account_info.duree,
        sol: data?.account_info.sol,
        nb_main: data?.account_info.nb_main,
        mutuelle: data?.account_info.mutuelle,
        duree_m: data?.account_info.duree_m,
      });

      setAccountType(data.account_info.type);
      setInitialAccountType(data.account_info.type); // Stocker le type initial pour la comparaison
      setInitialType(data.account_info.type || '');  // Initialise le type de départ
    } catch (error) {
      console.error("Erreur lors de la récupération du compte :", error);
    }
  };


  const handleAccountTypeChange = (e) => {
    const newType = e.target.value;
    setAccountType(e.target.value);
    if (e.target.value !== "PL") {
      setDuree("N/A"); // Réinitialise la durée si le type de compte n'est pas Plasman
    }
    setFormData({ ...formData, type: newType });

    // Active ou désactive le bouton en fonction de la comparaison avec la valeur initiale
    if (newType === initialType) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

//   const handleAccountTypeChange = (event) => {
//     setAccountType(event.target.value);
//     if (event.target.value !== "PL") {
//       setDuree("N/A"); // Réinitialise la durée si le type de compte n'est pas Plasman
//     }
//   };

  const handleDureeChange = (event) => {
    setDuree(event.target.value);
  };
  const handleSolChange = (event) => {
    setSol(event.target.value);
  };
  const handleNb_mainChange = (event) => {
    setNb_main(event.target.value);
  };
  const handleDuree_mChange = (event) => {
    setDuree_m(event.target.value);
  };
  const handleMutuelleChange = (event) => {
    setMutuelle(event.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const date_naissance = e.target.date_naissance.value;
    const lieu_naissance = e.target.lieu_naissance.value;
    const email = e.target.email.value;
    const tel = e.target.tel.value;
    const adresse = e.target.adresse.value;
    const statut = e.target.statut.value;
    const surnom = e.target.surnom.value;
    const type = e.target.type.value;
    const sexe = e.target.sexe.value;
    const devise = e.target.devise.value;
  
    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naissance", date_naissance);
    formData.append("email", email);
    formData.append("lieu_naissance", lieu_naissance);
    formData.append("tel", tel);
    formData.append("adresse", adresse);
    formData.append("statut", statut);
    formData.append("surnom", surnom);
    formData.append("type", type);
    formData.append("sexe", sexe);
    formData.append("devise", devise);
    formData.append("created_by", user.user_id);


    // const imageFile = e.target.image.files[0];
    // if (imageFile) {
    //   formData.append("type_piece", imageFile);
    // }

    setLoading(true);

    if (nom.length > 0) {
      // Choisir la fonction d'enregistrement selon le type de compte
      if (accountType === "PL" || accountType === "BS") {    
        if (accountType === "PL") {
          formData.append("duree", duree);
        } else {
          formData.append("duree", "N/A");
        }
        await createAccount(formData);
      } else if (accountType === "SOL") {
        formData.append("sol", sol);
        formData.append("nb_main", nb_main);
        await createCustomer(formData);
      } else if (accountType === "Mutuelle") {
        formData.append("mutuelle", mutuelle);
        formData.append("duree", duree_m);
        await createCustomerMutuelle(formData);
      } else {
        throw new Error("Type de compte non supporté");
      }
    }
  } catch (error) {
    console.error("Erreur lors de la création du compte :", error);
    alert("Erreur : " + error.message); // Affiche l'erreur pour plus de détails
  } finally {
    setLoading(false); // Désactiver le loader après l'exécution de la fonction de création appropriée
  }
};


  //   console.log(account);

  return (
    <div className="hold-transition  sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Gestion Clients Sol</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active">Clients</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <>
                <h2>Recherche de Compte</h2>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">
                        Plasman / Bousanm
                      </label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="sexe"
                        onChange={(e) => handlegetAccountByCode(e.target.value)}
                      >
                        {accounts.map((account, index) => (
                          <option key={index} value={account.num_compte}>{account.num_compte}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">Sol</label>

                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="sexe"
                        onChange={(e) => handlegetAccountByCode(e.target.value)}
                      >
                        <option value="">Selectionner un code</option>
                        {customers.map((customer, index) => (
                          <option key={index} value={customer.code}>{customer.code}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">Mutuelle</label>

                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="sexe"
                        onChange={(e) => handlegetAccountByCode(e.target.value)}
                      >
                        <option value="">Selectionner un code</option>
                        {customersMutuelle.map((customerMutuelle, index) => (
                          <option key={index} value={customerMutuelle.code}>{customerMutuelle.code}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        <div className="card">
          <div className="card-header">Informations Personnel</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nom"
                    name="nom"
              value={formData.nom}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="prenom">Prénom</label>
                  <input
                    type="text"
                    className="form-control"
                    id="prenom"
                    placeholder="Prenom"
                    name="prenom"
              value={formData.prenom}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">Sexe</label>
                  <select
                    className="form-control"
                    id="exampleSelectGender"
                    name="sexe"
              value={formData.sexe}
              onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Choisir le sexe
                    </option>
                    <option value={"M"}>Masculin</option>
                    <option value={"F"}>Feminin</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="date">Date naissance</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    placeholder="Date Naissance"
                    name="date_naissance"
              value={formData.date_naissance}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">Lieu de Naissance</label>
                  <input
                    type="test"
                    className="form-control"
                    id="Adresse"
                    placeholder="Lieu de naissance"
                    name="lieu_naissance"
              value={formData.lieu_naissance}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Adresse">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="Adresse"
                    placeholder="Numero d'ordre 9e"
                    name="email"
              value={formData.email}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">
                    Tel / Whatsapp
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    required
                    placeholder="Ecole 9e"
                    name="tel"
              value={formData.tel}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Ecole 9e"
                    name="adresse"
              value={formData.adresse}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="Téléphone">Statut</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    placeholder="Année Reussite en 9e"
                    name="statut"
              value={formData.statut}
              onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="Type" className="form-label">
                  Type de compte
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon12">
                    <span className="material-symbols-outlined">
                      credit_card
                    </span>
                  </span>
                  <select
                    className="form-select"
                    name="type"
                    required
                    aria-label="Type de compte"
                    id="Type"
              value={formData.type}
                    onChange={handleAccountTypeChange}
                  >
                    <option value="">Choisir un type</option>
                    <option value="BS">Bousanm</option>
                    <option value="PL">Plasman</option>
                    <option value="SOL">Sol</option>
                    <option value="Mutuelle">Mutuelle</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="exampleSelectGender">
                    Nom Utilisateur / Surnom
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Téléphone"
                    required
                    placeholder="Ecole 9e"
                    name="surnom"
              value={formData.surnom}
              onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Informations du Compte</div>
          <div className="card-body">
            <div className="row">
            <div className="col-md-6">
                          <label htmlFor="Type" className="form-label">
                            Devise
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon12"
                            >
                              <span className="material-symbols-outlined">
                                credit_card
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="devise"
                              required
                              aria-label="Devise"
                              id="Type"
              value={formData.devise}
              onChange={handleChange}
                              // onChange={handleAccountTypeChange}
                            >
                              <option value="">Choisir une devise</option>
                              <option value="HTG">HTG</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                        </div>

              {/* Affichage conditionnel pour PL */}
              {accountType === "PL" && (
                <div className="col-md-6">
                  <label htmlFor="duree" className="form-label">Durée</label>
                  <div className="input-group">
                    <span className="input-group-text"><span className="material-symbols-outlined">timer</span></span>
                    <select
                      className="form-select"
                      name="duree"
                      id="duree"
                      value={duree}
                      onChange={handleDureeChange}
                    >
                      <option value="N/A">N/A</option>
                      <option value="3">3 Mois</option>
                      <option value="6">6 Mois</option>
                      <option value="9">9 Mois</option>
                      <option value="12">12 Mois</option>
                    </select>
                  </div>
                </div>
              )}

              {/* Affichage conditionnel pour Sol */}
              {accountType === "SOL" && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="sol" className="form-label">Sol</label>
                    <div className="input-group">
                      <span className="input-group-text"><span className="material-symbols-outlined">Wc</span></span>
                      <select className="form-select" name="sol"
                      value={sol}
                      onChange={handleSolChange}
                       required>
                        <option value="">Choisir sol</option>
                        <option value="250">250 Gdes</option>
                        <option value="500">500 Gdes</option>
                        <option value="1000">1000 Gdes</option>
                        <option value="1500">1500 Gdes</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nombre de main</label>
                      <input
                        type="number"
                        className="form-control"
                        name="nb_main"
                        value={nb_main}
                        onChange={handleNb_mainChange}
                        step="0.01"
                        min="0.5"
                        required
                        onInput={(e) => {
                          const value = parseFloat(e.target.value);
                          e.target.setCustomValidity(value < 0.5 ? "Veuillez entrer un nombre supérieur ou égal à 0.5." : "");
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Affichage conditionnel pour Mutuelle */}
              {accountType === "Mutuelle" && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mutuelle | Montant/Jours</label>
                      <input type="number" className="form-control"
                      
                      value={mutuelle}
                      onChange={handleMutuelleChange}
                       name="mutuelle" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Durée</label>
                      <input type="number" className="form-control" name="duree_m"
                      
                      value={duree_m}
                      onChange={handleDuree_mChange} required />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-9  text-right">
                <label htmlFor="Téléphone" className="text-white">
                  .
                </label>
                <div className="form-group">
                  {/* <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                        Enregistrement en cours...
                      </>
                    ) : (
                      <>
                        <i className="fas fa-save"></i> Enregistrer
                      </>
                    )}
                  </button> */}
                   <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={isButtonDisabled || loading} 
            // disabled={!isTypeModified || loading}
          >
            {loading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i> Enregistrement en cours...
              </>
            ) : (
              <>
                <i className="fas fa-save"></i> Enregistrer
              </>
            )}
          </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
              </>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AccountSearch;
