import React, { createContext, useContext } from "react";
import AuthContext from "./AuthContext";
import { useHistory } from "react-router-dom";
import { baseUrlCustomers } from "./BaseUrl";
const swal = require("sweetalert2");
const baseUrl = baseUrlCustomers;

const CustomerContext = createContext();

const CustomerProvider = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authTokens, fetchWithAuth} = useContext(AuthContext);

  const history = useHistory();


  const getCustomers = async () => {
    const response = await fetchWithAuth(`${baseUrl}customers/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getCustomersMutuelle = async () => {
    const response = await fetchWithAuth(`${baseUrl}customers-mutuelle/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getCustomerById = async (CustomerId) => {
    const response = await fetchWithAuth(`${baseUrl}customers/${CustomerId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data);

    return data;
  };

  const getCustomerByCode = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}get-Customer-by-code/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const deleteCustomers = async (CustomerId) => {
    const response = await fetchWithAuth(`${baseUrl}customers/${CustomerId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const updateCustomers = async (CustomerId, formData) => {
    const response = await fetchWithAuth(`${baseUrl}customers/${CustomerId}/`, {
      method: "PUT",
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Customer Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/customers");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createCustomer = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}customers/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Inscription Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      window.location.reload();
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createCustomerMutuelle = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}customers-mutuelle/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Customer Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // history.push("/customers");\
      window.location.reload();
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const getCustomerBySurnom = async (code) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}customer-accounts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  const getCustomerMutuelleByTel = async (tel) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}customer-mutuelle-accounts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tel }),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  // Ajoutez cette fonction à `contextData` dans votre `CustomerProvider`
  const contextData = {
    createCustomer,
    getCustomers,
    getCustomerById,
    updateCustomers,
    deleteCustomers,
    getCustomerByCode,
    createCustomerMutuelle,
    getCustomersMutuelle,
    getCustomerBySurnom,
    getCustomerMutuelleByTel,
  };

  return (
    <CustomerContext.Provider value={contextData}>
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext, CustomerProvider };
