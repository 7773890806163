import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
// import DataAccounts from "../../data/DataAccounts";
import Footer from "../../Include/Footer";
import { DataAccounts } from "../../data/DataAccounts";

function ListAcount() {
  return (
    <>
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestions des Comptes</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">Comptes</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              <div className="card p-4">
                <div className="row mb-4">
                  <div className="col-10">
                    <h2 className="">Liste des Comptes</h2>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <Link
                      to="/add-account"
                      className="btn btn-primary btn-simple"
                    >
                      <i className="fas  fa-user-plus"></i>
                    </Link>
                  </div>
                </div>
                <DataAccounts />
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </main>
      </>
  );
}

export default ListAcount;
