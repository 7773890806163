import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { AccountContext } from "../../context/AccountsContext";
import { useParams, Link } from "react-router-dom";
import Footer from "../../Include/Footer";

function ViewsAccount() {
  const { getClientById } = useContext(AccountContext);
  const [accountType, setAccountType] = useState("");
  const [records, setRecords] = useState({});
  const { accounts_id } = useParams();
  const [profileImageView, setProfileImageView] = useState(null);

  useEffect(() => {
    getClientById(accounts_id).then((data) => {
      setRecords(data);
      console.log({data});
      
      setAccountType(records?.type || "");
      if (data.image) {
        setProfileImageView(data.image);
      }
    });
  }, [getClientById, accounts_id, records?.type]);
  // console.log("Donnees: "+ records);
  
  return (
    <div className="layout-fixed sidebar-expand-lg bg-body-tertiary">
      <div className="app-wrapper">
        <Sidebar />
  
        <main className="app-main">
          
        <div className="app-content-header">
           
           <div className="container-fluid">
             
             <div className="row">
               <div className="col-sm-6"><h3 className="mb-0">Dashboard</h3></div>
               <div className="col-sm-6">
               
               <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Acceuil</Link>
                    </li>
                    <li className="breadcrumb-item">Compte</li>
                  </ol>
               </div>
             </div>
             
           </div>
           
         </div>
       
         <div className="app-content">
           
            <div className="container-fluid">
            
              <div className="card p-3">
                <div className="col-md-12 mb-2">
                  <div
                    className={`btn rounded-lg ${
                      records?.isActive === "Actif" ? "btn-danger" : "btn-success"
                    }`}
                  >
                    Etat du Compte : {records?.etat|| ""}
                  </div>
                  <Link
                    to="/List"
                    className="btn btn-primary rounded-lg float-end"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                  <hr />
                </div>
                <form className="row g-3 mx-auto" encType="multipart/form-data">
                
                <div className="card-header bg-white mb-1">Informations Compte</div>
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="name" className="form-label">
                      Nom
                    </label>
                    <div className="input-group">
                    
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.nom || ""}
                        onChange={(e) =>
                          setRecords({ ...records, nom: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="prenom" className="form-label">
                      Prenom
                    </label>
                    <div className="input-group">
                     <input
                        type="text"
                        name="prenom"
                        placeholder="Jeanne"
                        readOnly
                        value={records?.prenom || ""}
                        onChange={(e) =>
                          setRecords({ ...records, prenom: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="prenom"
                        aria-label="prenom"
                        aria-describedby="basic-addon4"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <div className="input-group">
                     <input
                        type="email"
                        placeholder="jeannejoseph@gmail.com"
                        name="email"
                        readOnly
                        value={records?.client_email || ""}
                        onChange={(e) =>
                          setRecords({ ...records, client_email: e.target.value })
                        }
                        className="form-control form-control-sm"
                        id="email"
                        aria-label="email"
                        aria-describedby="basic-addon5"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="Numero_Whatsapp" className="form-label">
                      Téléphone
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="tel"
                        placeholder="+50912345678"
                        value={records?.client_tel || ""}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, client_tel: e.target.value })
                        }
                        className="form-control form-control-sm"
                        id="Numero_Whatsapp"
                        aria-label="Numero_Whatsapp"
                        aria-describedby="basic-addon6"
                      />
                    </div>
                  </div>

                </div>
                  <div className="card-header bg-white mb-1">Informations Compte</div>
                  <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="name" className="form-label">
                      Numero Compte
                    </label>
                    <div className="input-group">
                    
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.code || ""}
                        onChange={(e) =>
                          setRecords({ ...records, code: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name" className="form-label">
                      Type Compte
                    </label>
                    <div className="input-group">
                    
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.type_compte || ""}
                        onChange={(e) =>
                          setRecords({ ...records, type_compte: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name" className="form-label">
                      Devise
                    </label>
                    <div className="input-group">
                    
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.devise || ""}
                        onChange={(e) =>
                          setRecords({ ...records, devise: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="prenom" className="form-label">
                      Solde BS
                    </label>
                    <div className="input-group">
                     <input
                        type="text"
                        name=""
                        placeholder="Jeanne"
                        readOnly
                        value={records?.solde_BS || 0}
                        onChange={(e) =>
                          setRecords({ ...records, solde_BS: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="prenom"
                        aria-label="prenom"
                        aria-describedby="basic-addon4"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="email" className="form-label">
                      Solde Placement
                    </label>
                    <div className="input-group">
                     <input
                        type="email"
                        placeholder="jeannejoseph@gmail.com"
                        name="email"
                        readOnly
                        value={records?.solde_PL || 0}
                        onChange={(e) =>
                          setRecords({ ...records, solde_PL: e.target.value })
                        }
                        className="form-control form-control-sm"
                        id="email"
                        aria-label="email"
                        aria-describedby="basic-addon5"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="Numero_Whatsapp" className="form-label">
                      Solde Mutuelle
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="tel"
                        placeholder="+50912345678"
                        value={records?.solde_M || 0}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, solde_M: e.target.value })
                        }
                        className="form-control form-control-sm"
                        id="Numero_Whatsapp"
                        aria-label="Numero_Whatsapp"
                        aria-describedby="basic-addon6"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="Numero_Whatsapp" className="form-label">
                      Solde Sol
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="tel"
                        placeholder="+50912345678"
                        value={records?.solde_S || 0}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, solde_S: e.target.value })
                        }
                        className="form-control form-control-sm"
                        id="Numero_Whatsapp"
                        aria-label="Numero_Whatsapp"
                        aria-describedby="basic-addon6"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name" className="form-label">
                      Date Creation
                    </label>
                    <div className="input-group">
                    
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.created_at || ""}
                        onChange={(e) =>
                          setRecords({ ...records, created_at: e.target.value })
                        }
                        required
                        className="form-control form-control-sm"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        <Footer />
      </main>
    </div>
    </div>
  );
}

export default ViewsAccount;
