import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { DataMainToucher } from "../../data/DataTransactions";

function Paiement() {
  return (
    <>
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestion Paiement</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/transaction">Transactions</Link>
                    </li>
                    <li className="breadcrumb-item">Paiements</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              
              <Link
                className="btn btn-success py-1 btn-sm"
                to="/add-pay"
              >
                Nouveeau Paiement
              </Link>
              <div className="row mt-3">
                <DataMainToucher />
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </main>
      </>
  );
}

export default Paiement;
