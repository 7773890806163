import React, { useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { DataTransactions } from "../../data/DataTransactions";
import { Modal } from "react-bootstrap";

function ListTransaction() {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };

  return (
      <>
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestion Transactions</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">Transaction</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              <Link
                className="col-6 align-items-right"
                to="/add_transaction"
              ></Link>
              <Link className="btn btn-warning py-1 me-1" to="/add-renewal">
                Renouvellement
              </Link>
              <button
                className="btn btn-primary  py-1 me-1"
                onClick={handleSubmit}
              >
                Retrait Interet
              </button>
              <Link
                className="btn btn-success py-1 btn-sm"
                to="/add-transaction"
              >
                Nouvelle Transaction
              </Link>
              <div className="row mt-3">
                <DataTransactions />
              </div>
            </div>
          </div>

          <Modal
            className="mt-0"
            show={showModal}
            onHide={closeModal}
            size="lg"
            top="true"
          >
            <Modal.Header closeButton>
              <Modal.Title> Retraits Intérêt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-xl-12 col-lg-5">
                <div className="container-fluid">
                  <div className="card-body">
                    <form
                      className="row g-2 mx-auto"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-6 " />

                      <div className="col-md-6 mb-4">
                        <div className="input-group">
                          <input
                            type="number"
                            placeholder="Rechercher"
                            name="new_balance"
                            id="new_balance"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                            className="form-control form-control-sm"
                          />
                          <button
                            type="submit"
                            className="btn btn-primary form-control-sm"
                          >
                            <span className="material-symbols-outlined">
                              search
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-group">
                          <input
                            type="text"
                            disabled
                            placeholder="Actif"
                            name="new_balance"
                            className="form-control form-control-sm"
                            id="new_balance"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-group">
                          <input
                            type="text"
                            disabled
                            placeholder="Balance"
                            name="new_balance"
                            required
                            className="form-control form-control-sm"
                            id="new_balance"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-group">
                          <input
                            type="text"
                            disabled
                            placeholder="Interet"
                            name="new_balance"
                            required
                            className="form-control form-control-sm"
                            id="new_balance"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="imput-group">
                          <button
                            type="button"
                            className="btn btn-primary float-end"
                            data-mdb-ripple-init
                          >
                            Retrait
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* <Footer /> */}
        </main>
      </>
  );
}

export default ListTransaction;
