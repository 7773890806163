import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormUsers } from "../../data/Form";
import DataUsers from "../../data/DataUsers";
import Sidebar from "../../Include/Sidebar";
import Footer from "../../Include/Footer";
function User() {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };
  useEffect(() => {
		document.title = "H-SOL | Users";
	  }, [])
  return (
    <>
        <Sidebar />
  
        <main className="app-main">
          
        <div className="app-content-header">
           
           <div className="container-fluid">
             
             <div className="row">
               <div className="col-sm-6"><h3 className="mb-0">Dashboard</h3></div>
               <div className="col-sm-6">
                 <ol className="breadcrumb float-sm-end">
                   <li className="breadcrumb-item"><a href="#">Home</a></li>
                   <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                 </ol>
               </div>
             </div>
             
           </div>
           
         </div>
       
         <div className="app-content">
           
            <div className="container-fluid">
            
            <div className="row">
                <div className="row">
                  <div className="col-6 ">
                  </div> 
                  <div className=" col-6 p-2 pl-0">
                    <button 
                      className="btn btn-primary p-1 fw-bold float-end"
                      onClick={handleSubmit}
                    >
                      <i className="fas fa-user-plus"> </i>
                      <span> </span>Nouveau Utilisateur
                    </button>
                  </div>
                </div>
                  <DataUsers />
              </div>
            
          <Modal
          variant='modal-dialog'
            show={showModal}
            onHide={closeModal}
            size="xl"
            top="true"
          >
            <Modal.Header closeButton>
              <Modal.Title>Formulaire d'enregistrement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-body">
                  <FormUsers />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="btn btn-danger" onClick={closeModal}>
                <i className="fas fa-trash"></i> Fermer
              </Button>
            </Modal.Footer>
          </Modal>
            </div>
         
          </div>
          
      {/* <Footer/> */}
      </main>
    </>
  )
}

export default User;
