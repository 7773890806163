/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { AccountContext } from "../context/AccountsContext";
import LoaderData from "../Include/loaderData/loaderData";
import AuthContext from "../context/AuthContext";
import { TransactionsContext } from "../context/TransactionsContext";
import 'jspdf-autotable'; // Importez jspdf-autotable
import jsPDF from 'jspdf';
const swal = require("sweetalert2");


const exportPDF = async (data, typeCompte) => {
  const doc = new jsPDF('p', 'pt', 'letter');

  // // Add background image
  // const img = new Image();
  // img.src = '/img/filig.png';

  // await new Promise((resolve) => {
  //   img.onload = resolve;
  // });

  // const canvas = document.createElement('canvas');
  // canvas.width = 612;
  // canvas.height = 792;
  // const context = canvas.getContext('2d');
  // context.drawImage(img, 0, 0, canvas.width, canvas.height);

  // const imgData = canvas.toDataURL('image/png');

  // // Add the background image to the PDF
  // doc.addImage(imgData, 'PNG', 0, 0, 612, 792);

  // // Header
  // doc.addImage('/img/header.png', 'PNG', 0, 0, 612, 130);

  // Student Info
  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text(`Liste des Comptes`, 25, 60);

  // Sort data by concatenated 'nom' and 'prenom'
  const sortedData = data.sort((a, b) => {
    const fullNameA = `${a.client_nom} ${a.client_prenom}`.toLowerCase();
    const fullNameB = `${b.client_nom} ${b.client_prenom}`.toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  });

  // Notes Table
  const tableColumn = ["code", "Nom", "Prenom", "username", "password"];
  const tableRows = sortedData.map((note, index) => [
    note.code,
    note.client_nom,
    note.client_prenom,
    note.username,
    note.pass_send,
  ]);

  doc.autoTable({
    startY: 70,
    head: [tableColumn],
    body: tableRows,
    theme: 'grid',
    styles: {
      font: 'times',
      fontSize: 11,
      cellPadding: 2,
      overflow: 'linebreak'
    },
    headStyles: { halign: 'center', valign: 'middle', fillColor: [1, 80, 133], textColor: [255, 255, 255] },
    bodyStyles: {
      valign: 'middle',
      textColor: [22, 22, 22],
      halign: 'center'
    },
    columnStyles: {
      0: { cellWidth: 100, halign: 'center' },
      1: { cellWidth: 100, halign: 'left', fontStyle: 'bold' },
      2: { cellWidth: 100, halign: 'left', fontStyle: 'bold' },
    }
  });

  doc.save(`Liste.pdf`);
}

const DataAccounts = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const { getAccounts, activateDeactivateAccount } = useContext(AccountContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedAccount, setSelectedAccount] = useState("Tous les comptes"); // État pour suivre la classe sélectionnée

  const [selectedClasse, setSelectedClasse] = useState('Toutes les classes');

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAccounts();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getAccounts]);
  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }

  // const exportPDF = async (dataToExport, typeCompte) => {
  //   const doc = new jsPDF();

  //   // Ajouter un titre
  //   doc.text(`Rapport pour le type de compte : ${typeCompte}`, 10, 10);

  //   // Préparer les données pour le tableau
  //   const tableData = dataToExport.map((row) => [
  //     row.code,
  //     row.client_nom,
  //     row.client_prenom,
  //     row.username,
  //     row.pass_send,
  //   ]);

  //   // Ajouter un tableau
  //   doc.autoTable({
  //     head: [['Code','Nom', 'Prénom', 'Classe', 'Type de Compte']],
  //     body: tableData,
  //   });

  //   // Enregistrer le fichier PDF
  //   doc.save(`Rapport_${typeCompte}.pdf`);
  // };

  const handleExport = async (type) => {
    setLoadingExport(true); // Activer le loader
    try {
      const dataToExport =
        selectedClasse === 'Toutes les classes'
          ? records
          : originalRecords.filter(
              (row) => row.classe_nom.toLowerCase() === selectedClasse.toLowerCase()
            );

      const typeCompte = dataToExport.length > 0 ? dataToExport[0].type_compte : ''; // Récupérer le type de compte

      if (dataToExport.length === 0) {
        swal.fire({
          title: 'Table vide',
          text: 'Aucune donnée à exporter',
          icon: 'warning',
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      switch (type) {
        case 'pdf':
          await exportPDF(dataToExport, typeCompte); // Ajouter `await` pour attendre la génération
          break;
        // Ajoutez d'autres cas si nécessaire...
        default:
          swal.fire({
            title: 'Type non supporté',
            text: 'Seul le type PDF est supporté pour le moment',
            icon: 'info',
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          });
      }
    } finally {
      setLoadingExport(false); // Désactiver le loader
    }
  };


  const columnsAccount = [
    {
      name: "Numero",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.client_nom + " " + row.client_prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Type - Devise",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.type_compte + " - " + row.devise}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Solde BS",
      selector: (row) => row.solde_BS,
      sortable: true,
    },
    {
      name: "Solde Sol",
      selector: (row) => row.solde_S,
      sortable: true,
    },
    {
      name: "Mutuelle",
      selector: (row) => row.solde_M,
      sortable: true,
    },
    {
      name: "Pass",
      selector: (row) => row.pass_send,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        let isActive = false;
        if (row.etat === "actif") {
          isActive = true;
        }
        return (
          <div className="btn-group" role="group">
            <Link
              to={"/views-account/" + row.id}
              className="btn btn-primary btn-sm me-1"
            >
              <i className="fas fa-eye"></i>
            </Link>
            <button
              className={`btn btn-${isActive ? "success" : "danger"
                } btn-sm w-100 me-1`}
              onClick={() => handleActivateDeactivate(row.id)}
            >
              {isActive ? <i class="fas fa-check-circle" style={{ color: "white", fontSize: "15px" }}></i>
                : <i class="fas fa-ban" style={{ color: "white", fontSize: "15px" }}></i>
              }
            </button>
            <Link
              to={"/new-account/" + row.id}
              className="btn btn-info btn-sm me-1"
            >
              <i className="fas fa-plus"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const handleActivateDeactivate = async (id) => {
    try {
      await activateDeactivateAccount(id);
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshData = async () => {
    try {
      const data = await getAccounts();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.client_nom.toLowerCase();
      const num_compteLower = row.code.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  const handleFilterByAccount = (type) => {
    setSelectedAccount(type);
    if (type === "Tous les comptes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.type_compte.toLowerCase() === type.toLowerCase()
      );
      setRecords(newData);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-4 ">
      <button className="btn btn-warning btn-sm" onClick={() => handleExport('pdf')} disabled={loadingExport}>
        {loadingExport ? 'Exportation...' : 'Exporter en PDF'}
      </button>

        </div>
        <div className="col-sm-4 text-start d-flex align-items-center">
          <ExportDropdown
            id="dropdown-item-button"
            title={`Trier par ${selectedAccount}`}
            menualign="right"
            variant="outline-primary btn btn-primary text-white btn-sm mb-0"
          >
            <Dropdown.ItemText>Selectionner un type</Dropdown.ItemText>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("Tous les comptes")}
            >
              Tous les comptes
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("BS")}
            >
              Bousanm
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("SOL")}
            >
              Sol
            </Dropdown.Item>
          </ExportDropdown>
        </div>

        <div className="col-sm-4 d-flex justify-content-end">
          <input
            className="form-control form-control-sm float-end"
            type="text"
            placeholder="Rechercher..."
            onChange={handleFilter}
          />
        </div>

        <div className="col-md-12 ">
          <DataTable
            columns={columnsAccount}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};
// export default DataAccounts;

const DataAccountsValidate = () => {
  const { getAccountsValidate, ValidateOnlineAccount } = useContext(AccountContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const { user } = useContext(AuthContext);
  const [selectedAccount, setSelectedAccount] = useState("Tous les comptes"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAccountsValidate();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getAccountsValidate]);
  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsAccount = [
    {
      name: "Numero",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.client_nom + " " + row.client_prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Type - Devise",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.type_compte + " - " + row.devise}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Solde BS",
      selector: (row) => row.solde_BS,
      sortable: true,
    },
    {
      name: "Solde Sol",
      selector: (row) => row.solde_S,
      sortable: true,
    },
    {
      name: "Mutuelle",
      selector: (row) => row.solde_M,
      sortable: true,
    },
    {
      name: "Pass",
      selector: (row) => row.pass_send,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        let isActive = false;
        if (row.etat === "actif") {
          isActive = true;
        }
        return (
          <div className="btn-group" role="group">
            
            <button
              className="btn btn-success btn-sm w-100 me-1"
              onClick={() => handleActivateDeactivate(row.client,row.id,user.user_id)}
            >
              <i class="fas fa-check-circle" style={{ color: "white", fontSize: "15px" }}></i>
           
            </button>
          </div>
        );
      },
    },
  ];

  const handleActivateDeactivate = async (client_id,account_id,user_id) => {
    try {
      await ValidateOnlineAccount(client_id,account_id,user_id);
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshData = async () => {
    try {
      const data = await getAccounts();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.agent.toLowerCase();
      const num_compteLower = row.date.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  const handleFilterByAccount = (type) => {
    setSelectedAccount(type);
    if (type === "Tous les comptes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.type_compte.toLowerCase() === type.toLowerCase()
      );
      setRecords(newData);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8 text-start d-flex align-items-center">
          <ExportDropdown
            id="dropdown-item-button"
            title={`Trier par ${selectedAccount}`}
            menualign="right"
            variant="outline-primary btn btn-primary text-white btn-sm mb-0"
          >
            <Dropdown.ItemText>Selectionner un type</Dropdown.ItemText>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("Tous les comptes")}
            >
              Tous les comptes
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("BS")}
            >
              Bousanm
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("SOL")}
            >
              Sol
            </Dropdown.Item>
          </ExportDropdown>
        </div>

        <div className="col-md-4 d-flex justify-content-end">
          <input
            className="form-control form-control-sm float-end"
            type="text"
            placeholder="Rechercher..."
            onChange={handleFilter}
          />
        </div>

        <div className="col-md-12 ">
          <DataTable
            columns={columnsAccount}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};

const DataHistory = () => {
  const { getHistory } = useContext(TransactionsContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState({}); // État pour gérer les spinners par fichier

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getHistory();
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getHistory]);

  if (loading) {
    return <LoaderData />;
  }

  const handleDownload = async (fileUrl, id) => {
    setDownloading((prev) => ({ ...prev, [id]: true })); // Activer le spinner pour ce fichier
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileUrl.split("/").pop(); // Nom du fichier
      link.click();
    } catch (error) {
      console.error("Erreur de téléchargement :", error);
    } finally {
      setDownloading((prev) => ({ ...prev, [id]: false })); // Désactiver le spinner
    }
  };

  const columnsAccount = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Agent",
      selector: (row) => row.agent,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.formatted_date,
      sortable: true,
    },
    {
      name: "Fichier",
      cell: (row) => (
        <div className="btn-group" role="group">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleDownload(row.file, row.id)}
            disabled={downloading[row.id]} // Désactiver le bouton si téléchargement en cours
          >
            {downloading[row.id] ? (
              <i className="fas fa-spinner fa-spin"> En cours...</i>  // Spinner
            ) : (
              <><i className="fas fa-download me-2"> </i> 
              Télécharger le Fichier
              </>
            )}
          </button>
        </div>
      ),
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords);
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.agent.toLowerCase();
      const searchLower = value.toLowerCase();
      return nomLower.includes(searchLower);
    });
    setRecords(newData);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8 text-start d-flex align-items-center">
        
        </div>

        <div className="col-md-4 d-flex justify-content-end">
          <input
            className="form-control form-control-sm float-end"
            type="text"
            placeholder="Rechercher..."
            onChange={handleFilter}
          />
        </div>

        <div className="col-md-12">
          <DataTable
            columns={columnsAccount}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};

export default DataHistory;
export { DataAccounts, DataAccountsValidate,DataHistory };
