import React, { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
// Form Student

export const FormUsers = () => {
  const [profileImage, setProfileImage] = useState(null);
  const { createUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // État de chargement

  const handleSubmit = async (e) => {
    e.preventDefault();
    const first_name = e.target.first_name.value;
    const last_name = e.target.last_name.value;
    const email = e.target.email.value;
    const username = e.target.username.value;
    const role = e.target.role.value;
    const password = e.target.password.value;
    const is_active = e.target.is_active.value;
    const is_staff = e.target.is_staff.value;

    let formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("role", role);
    formData.append("password", password);
    formData.append("is_staff", is_staff);
    formData.append("is_active", is_active);

    const imageFile = e.target.image.files[0];
    if (imageFile) {
      formData.append("image", imageFile);
    }

    // Valide l'email avant d'envoyer la requête
    if (email.length > 0 && email.includes("@") && email.includes(".")) {
      setLoading(true);
      try {
        await createUser(formData);
      } catch (error) {
        console.error("Erreur de connexion :", error);
      } finally {
        setLoading(false); // Remettre loading à false après la fin du processus
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        {profileImage && (
          <div className="col-md-3">
            <label htmlFor="profil">Photo</label>
            <div className="form-group">
              <img
                src={profileImage}
                id="profil"
                alt="profil"
                style={{
                  maxWidth: "80px",
                  maxHeight: "80px",
                  borderRadius: "50px",
                }}
              />
            </div>
          </div>
        )}
        <>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nom Utilisateur"
                required
                name="first_name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Prenom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nom Utilisateur"
                required
                name="last_name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputEmail3">Email</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail3"
                placeholder="Email"
                required
                name="email"
              />
            </div>
          </div>
        </>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Nom Utilisateur</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Nom Utilisateur"
              required
              name="username"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Mot de passe</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Nom Utilisateur"
              required
              name="password"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleSelectGender">Role</label>
            <select
              name="role"
              className="form-control"
              id="exampleSelectGender"
            >
              <option selected readonly></option>
              <option value={"Admin"}>Administrateur</option>
              <option value={"User"}>Utilisateur</option>
              <option>Caissier</option>
              <option>Agent</option>
            </select>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="verified">Staff</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="verified"
              name="is_staff"
            />
            <label className="form-check-label" htmlFor="verified">
              Vérifié
            </label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="verified">Etat du compte</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="verified"
              name="is_active"
            />
            <label className="form-check-label" htmlFor="verified">
              Actif | Inactif
            </label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="exampleInputFile">Fichier importer</label>
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="exampleInputFile"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
              <label className="custom-file-label" htmlFor="exampleInputFile">
                {profileImage ? "Image sélectionnée" : "Sélectionner une image"}
              </label>
            </div>
            <div className="input-group-append">
              <span className="input-group-text">Importer</span>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4 text-center">
          <div className="form-group pt-1">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> En cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
            {/* {loading && <LoaderData />} */}
          </div>
        </div>
      </div>
    </form>
  );
};

export const FormAccounts = () => {

  return (
    <div className="form-container">

    </div>
  );
};
