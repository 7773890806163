// App.js
import React, { useEffect } from 'react';
import AppRoutes from './components/routes/Routes';
function App() {

  useEffect(() => {
    // Assurez-vous que AdminLTE est initialisé après le DOM
    const script = document.createElement('script');
    script.src = '/js/adminlte.js';
    script.onload = () => {
      console.log('AdminLTE initialisé');
    };
    document.body.appendChild(script);

    return () => {
      // Nettoyez si nécessaire
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
