import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";
import Footer from "../../Include/Footer";
import { useParams } from "react-router-dom";

function NewAccount() {
  const [accountType, setAccountType] = useState("");
  const [duree, setDuree] = useState("N/A"); // Définit la valeur par défaut à 'N/A'
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState({});
  const { accounts_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { createClient, getAccountById, createAccount, CancelClient, ValidateClient } = useContext(AccountContext);

  const [formData, setFormData] = useState({
    client_id : "",
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    sexe: "",
    date_naissance: "",
    lieu_naissance: "",
    adresse: "",
    type_compte: "",
    devise: "",
    nb_main: "",
    sol: "",
    frequence_paiement: "",
    duree_sol: "",
    date_debut_sol: "",
  });

  // Gestion des changements de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    getAccountById(accounts_id).then((data) => {
      setRecords(data);
    });
  }, [getAccountById, accounts_id]);

  const handleValidate = () => {
    let currentErrors = {};
      // Valider les champs pour l'étape 2
      if (!formData.type_compte) currentErrors.type_compte = "Le type de compte est requis.";
      if (!formData.devise) currentErrors.devise = "La devise est requise.";

      // Champs spécifiques pour le type "SOL"
      if (formData.type_compte === "SOL") {
        if (!formData.nb_main) currentErrors.nb_main = "Le nombre de mains est requis.";
        if (!formData.sol) currentErrors.sol = "Le sol est requis.";
        if (!formData.frequence_paiement) currentErrors.frequence_paiement = "La fréquence de paiement est requise.";
        if (!formData.duree_sol) currentErrors.duree_sol = "La durée du sol est requise.";
        if (!formData.date_debut_sol) currentErrors.date_debut_sol = "La date de début est requise.";
      }
    

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
    } else {
      setErrors({});
        // Soumettre les données si c'est la dernière étape
        handleSubmit();
      
    }
  };

  const handleSubmit = async () => {
    try {
      const {type_compte, devise, nb_main, sol, frequence_paiement, duree_sol, date_debut_sol } = formData;

      let formData2 = new FormData();
      formData2.append("client_id", records?.client_id);
      formData2.append("type_compte", type_compte);
      formData2.append("devise", devise);
      formData2.append("nb_main", nb_main);
      formData2.append("sol", sol);
      formData2.append("frequence_paiement", frequence_paiement);
      formData2.append("duree_sol", duree_sol);
      formData2.append("date_debut_sol", date_debut_sol);
      formData2.append("created_by", user.user_id);
      console.log(formData2.client_id);

      const response = await createAccount(formData2)
      if (response.id) {
        // Swal.fire("Succès", "Le compte a été créé avec succès", "success");
        // Réinitialiser le formulaire après succès
        setFormData({});
      } else {
        // Swal.fire("Erreur", "Une erreur est survenue lors de la création du compte.", "error");
      }
    } catch (error) {
      // Swal.fire("Erreur", "Impossible de soumettre les données.", "error");
    }
  };

  return (
    <div className="layout-fixed sidebar-expand-lg bg-body-tertiary">
      <div className="app-wrapper">
        <Sidebar />
  
        <main className="app-main">
          
        <div className="app-content-header">
           
           <div className="container-fluid">
             
             <div className="row">
               <div className="col-sm-6"><h3 className="mb-0">Dashboard</h3></div>
               <div className="col-sm-6">
               
               <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Acceuil</Link>
                    </li>
                    <li className="breadcrumb-item">Compte</li>
                  </ol>
               </div>
             </div>
             
           </div>
           
         </div>
       
         <div className="app-content">
           
            <div className="container-fluid">
            
              <div className="row mb-2">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-info"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12 ">
                      Nouveau Compte pour
                      <span className="fw-bold">
                        {" "}
                        {records?.code || ""}
                      </span>{" "}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid col-12 text-start ">
              <div className="card p-3">
                <div className="col-md-12 mb-2">
                  <Link
                    to="/List"
                    className="btn btn-primary rounded-lg float-end"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                </div>
                <hr />
                <div className="row p-2">
                  <div className="card p-0 col-xl-6 col-lg-5 ">
                    <div className="card-header bg-primary mb-1">
                      Detail client
                    </div>
                    <form
                      className="row g-3 mx-auto"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Nom Complet
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </span>
                          <input
                            type="text"
                            placeholder="Joseph"
                            name="nom"
                            readOnly
                            value={`${records?.nom} ${records?.prenom}` || ""}
                            required
                            className="form-control"
                            id="name"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="prenom" className="form-label">
                          Email
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon4">
                            <span className="material-symbols-outlined">
                              email
                            </span>
                          </span>
                          <input
                            type="text"
                            name="prenom"
                            placeholder="Jeanne"
                            readOnly
                            value={records?.client_email || ""}
                            onChange={(e) =>
                              setRecords({ ...records, client_email: e.target.value })
                            }
                            required
                            className="form-control"
                            id="prenom"
                            aria-label="prenom"
                            aria-describedby="basic-addon4"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label for="status" className="form-label">
                          Téléphone
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon12">
                            <span className="material-symbols-outlined">
                              grade
                            </span>
                          </span>
                          <input
                            type="text"
                            name="statut"
                            required
                            readOnly
                            value={records?.client_tel || ""}
                            onChange={(e) =>
                              setRecords({
                                ...records,
                                client_tel: e.target.value,
                              })
                            }
                            placeholder="Marchand | Etudiant..."
                            className="form-control"
                            id="status"
                            aria-label="status"
                            aria-describedby="basic-addon12"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card p-0 col-xl-6 col-lg-5">
                    <div className="card-header bg-primary ">
                      Ajouter le noouveau compte
                    </div>
                    {/* <form
                      onSubmit={handleSubmit}
                      className="row g-3 mx-auto"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <label htmlFor="Type" className="form-label">
                          Type de compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon12">
                            <span className="material-symbols-outlined">
                              credit_card
                            </span>
                          </span>
                          <select
                            className="form-select"
                            name="type"
                            required
                            aria-label="Type de compte"
                            id="Type"
                            onChange={handleAccountTypeChange}
                          >
                            <option value="">Choisir un type</option>
                            <option value="BS">Bousanm</option>
                            <option value="PL">Plasman</option>
                          </select>
                        </div>
                      </div>
                      {accountType === "PL" && (
                        <div className="col-md-">
                          <label htmlFor="duree" className="form-label">
                            Durée
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon13"
                            >
                              <span className="material-symbols-outlined">
                                timer
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="duree"
                              aria-label="Durée"
                              id="duree"
                              value={duree}
                              onChange={handleDureeChange}
                            >
                              <option value="N/A">N/A</option>
                              <option value="3">3 Mois</option>
                              <option value="6">6 Mois</option>
                              <option value="9">9 Mois</option>
                              <option value="12">12 Mois</option>
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <label htmlFor="Nif_Cin" className="form-label">
                          Devise
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon9">
                            <span className="material-symbols-outlined">
                              Wc
                            </span>
                          </span>
                          <select
                            className="form-select"
                            name="devise"
                            required
                            aria-label="Type de compte"
                            id="Nif_Cin"
                          >
                            <option value="">Choisir la devise</option>
                            <option value="HTG">HTG</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i>{" "}
                              Enregistrement en cours...
                            </>
                          ) : (
                            <>
                              <i className="fas fa-save"></i> Enregistrer
                            </>
                          )}
                        </button>
                      </div>
                    </form> */}
                      <div>
                    <div className="row g-3 mx-auto">
                      {/* Champ Type Compte */}
                      <div className="col-md-6">
                        <label htmlFor="validationDefault09" className="form-label">
                          Type Compte
                        </label>
                        <select
                          className={`form-control ${errors.type_compte && "is-invalid"}`}
                          id="validationDefault09"
                          name="type_compte"
                          value={formData.type_compte}
                          onChange={handleChange}
                        >
                          <option value="">
                            Choisissez...
                          </option>
                          <option value="BS">Bousanm</option>
                          <option value="SOL">Sol</option>
                        </select>
                        <span className="error"> {errors.type_compte ? errors.type_compte : <br />}</span>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="validationDefault10" className="form-label">Devise</label>
                        <select
                          className={`form-control ${errors.devise && "is-invalid"}`}
                          id="validationDefault10"
                          name="devise"
                          value={formData.devise}
                          onChange={handleChange}>
                          <option value="">Choisissez...</option>
                          <option value="HTG">Gourde</option>
                          <option value="USD">Dollars US</option>
                        </select>
                        <span className="error">{errors.devise ? errors.devise : <br />}</span>
                      </div>
                    </div>

                    {/* Afficher Nb Main et Sol si type_compte === "SOL" */}
                    {formData.type_compte === "SOL" && (
                      <div className="row g-3 mx-auto">
                        <div className="col-md-6 m-0">
                          <label htmlFor="nb_main" className="form-label">Nb Main</label>
                          <input
                            type="number"
                            step="any"  // Permet les valeurs décimales
                            className={`form-control ${errors.nb_main && "is-invalid"}`}
                            id="nb_main"
                            name="nb_main"
                            value={formData.nb_main}
                            onChange={handleChange}
                          />
                          <span className="error">{errors.nb_main ? errors.nb_main : <br />}</span>
                        </div>

                        <div className="col-md-6 m-0">
                          <label htmlFor="sol" className="form-label">Sol</label>
                          <input
                            type="number"
                            className={`form-control ${errors.sol && "is-invalid"}`}
                            id="sol"
                            name="sol"
                            value={formData.sol}
                            onChange={handleChange}
                          />
                          <span className="error">{errors.sol ? errors.sol : <br />}</span>
                        </div>
                        <div className="col-md-6 m-0">
                          <label htmlFor="validationDefault10" className="form-label">Frequence paiement</label>
                          <select
                            className={`form-control ${errors.frequence_paiement && "is-invalid"}`}
                            id="validationDefault13"
                            name="frequence_paiement"
                            value={formData.frequence_paiement}
                            onChange={handleChange}>
                            <option value="">Choisissez...</option>
                            <option value="jours">Jours</option>
                            <option value="semaine">Semaine</option>
                            <option value="mois">Mois</option>
                          </select>
                          <span className="error">{errors.frequence_paiement ? errors.frequence_paiement : <br />}</span>
                        </div>
                        <div className="col-md-6 m-0">
                          <label htmlFor="validationDefault10" className="form-label">Duree sol</label>
                          <input
                            type="number"
                            className={`form-control ${errors.duree_sol && "is-invalid"}`}
                            id="duree_sol"
                            name="duree_sol"
                            value={formData.duree_sol}
                            onChange={handleChange}
                          />
                          <span className="error">{errors.duree_sol ? errors.duree_sol : <br />}</span>
                        </div>
                        <div className="col-md-6 m-0">
                          <label htmlFor="validationDefault10" className="form-label">Date debut</label>
                          <input
                            type="date"
                            className={`form-control ${errors.date_debut_sol && "is-invalid"}`}
                            id="date_debut_sol"
                            name="date_debut_sol"
                            value={formData.date_debut_sol}
                            onChange={handleChange}
                          />
                          <span className="error">{errors.date_debut_sol ? errors.date_debut_sol : <br />}</span>
                        </div>
                      </div>
                    )}
                    <div className="row row g-3 mx-auto">

                    <div className="col-md-12 m-0"> 
                          <label htmlFor="validationDefault10" className="form-label text-white">.</label>
                          
                        <button className="btn btn-primary btn-sm float-end" onClick={handleValidate}>
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/* <Footer /> */}
      </main>
    </div>
    </div>
  );
}

export default NewAccount;
