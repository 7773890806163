import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import DataAccounts, { DataAccountsValidate } from "../../data/DataAccounts";
import Footer from "../../Include/Footer";

function ListAccountValidate() {
  return (
    <>
        <Sidebar />
  
        <main className="app-main">
          
        <div className="app-content-header">
           
           <div className="container-fluid">
             
             <div className="row">
               <div className="col-sm-6"><h3 className="mb-0">Dashboard</h3></div>
               <div className="col-sm-6">
                 <ol className="breadcrumb float-sm-end">
                   <li className="breadcrumb-item"><a href="#">Home</a></li>
                   <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                 </ol>
               </div>
             </div>
             
           </div>
           
         </div>
       
         <div className="app-content">
           
            <div className="container-fluid">
            
          <div className="card p-4" >
            <div className="row mb-4">
              <div className="col-10">
                <h2 className="">Liste des Comptes</h2>
              </div>
              <div className="col d-flex justify-content-end">
             
              </div>
            </div>
            <DataAccountsValidate />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      </main>
    </>
  );
}

export default ListAccountValidate;
