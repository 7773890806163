import React, { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "./AuthContext";
import { baseUrlTransactionsSol } from "./BaseUrl";
const swal = require("sweetalert2");
const baseUrl = baseUrlTransactionsSol;

const TransactionSolContext = createContext();

const TransactionSolProvider = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authTokens ,fetchWithAuth} = useContext(AuthContext);

  const history = useHistory();
  // Fonctions de gestion des professeurs


  const getTransactions = async () => {
    const response = await fetchWithAuth(`${baseUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getTransactionsMutuelle = async () => {
    const response = await fetchWithAuth(`${baseUrl}mutuelle/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getTransactionById = async (TransactionId) => {
    const response = await fetchWithAuth(`${baseUrl}${TransactionId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getTransactionByCode = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}get-transaction-by-code/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };
  const deleteTransactions = async (TransactionId) => {
    try {
      const response = await fetchWithAuth(
        `${baseUrl}transactions/${TransactionId}/cancel/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
             },
        }
      );

      // Vérifier le statut de la réponse
      if (response.status === 204 || response.status === 200) {
        swal.fire({
          title: "Transaction supprimée avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        // Gérer les autres statuts HTTP
        console.error(`Erreur serveur : ${response.status}`);
        swal.fire({
          title: "Une erreur s'est produite " + response.status,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs de réseau ou autres
      console.error("Erreur de réseau ou de requête :", error);
      swal.fire({
        title: "Une erreur s'est produite",
        text: error.message,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const updateTransactions = async (TransactionId, formData) => {
    const response = await fetchWithAuth(`${baseUrl}${TransactionId}/`, {
      method: "PUT",
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Transaction Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/Transactions");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createTransaction = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}create/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Transaction Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // history.push("/Transactions");\
      window.location.reload();
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createTransactionMutuelle = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}mutuelle/create/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Transaction Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // history.push("/Transactions");\
      window.location.reload();
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Rapport

  // Récupérer le montant total du jour
  const getMontantTotalDuJour = async () => {
    const response = await fetchWithAuth(`${baseUrl}montant-total-du-jour/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data.montant_total; // Supposons que la réponse ait un champ 'total'
  };

  // Récupérer le montant total des transactions entrées
  const getMontantTotalEntree = async () => {
    const response = await fetchWithAuth(`${baseUrl}montant-total-entree/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data.montant_total; // Supposons que la réponse ait un champ 'total'
  };

  // Récupérer le montant des jours précédents
  const getMontantJoursPrecedents = async () => {
    const response = await fetchWithAuth(`${baseUrl}montant-jours-precedents/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data.montant_total; // Supposons que la réponse ait un champ 'total'
  };

  // Ajoutez cette fonction à `contextData` dans votre `TransactionSolProvider`
  const contextData = {
    createTransaction,
    getTransactions,
    getTransactionsMutuelle,
    getTransactionById,
    updateTransactions,
    deleteTransactions,
    getTransactionByCode,
    createTransactionMutuelle,
    getMontantTotalDuJour,
    getMontantTotalEntree,
    getMontantJoursPrecedents,
  };

  return (
    <TransactionSolContext.Provider value={contextData}>
      {children}
    </TransactionSolContext.Provider>
  );
};

export { TransactionSolContext, TransactionSolProvider };
