import React, { createContext, useContext, useEffect, useState } from 'react';
import { baseUrlTimer } from './BaseUrl';
const baseUrl = baseUrlTimer;

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const [loading1, setLoading] = useState(false);

  const fetchTimerData = async (clientId) => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}next/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ client_id: clientId }),
      });

      if (!response.ok) {
        throw new Error(`Erreur ${response.status}: ${response.statusText}`);
      }
  
      const newData = await response.json();
      setTimeLeft((prev) => ({ ...prev, [clientId]: newData }));
    } catch (error) {
      console.error('Erreur lors de l\'appel API', error);
    } finally {
      setLoading(false);
    }
  };
  
  // useEffect(() => {
  //   // Exemple d'ID de compte (remplacer par des ID dynamiques si nécessaire)
  //   const clientIds = ['client_id_1', 'client_id_2']; // Liste des IDs des comptes
  //   const fetchAllTimers = async () => {
  //     for (const clientId of clientIds) {
  //       await fetchTimerData(clientId);
  //     }
  //   };

  //   fetchAllTimers();
  //   const apiInterval = setInterval(fetchAllTimers, 3600000);

  //   return () => clearInterval(apiInterval);
  // }, []);

  return (
    <TimerContext.Provider value={{ timeLeft, setTimeLeft, loading1,fetchTimerData }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
