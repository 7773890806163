/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import Loader from "../Include/loader/loader";
import { Link as RouterLink } from "react-router-dom";

export default function Login() {
  const { loginUser, isOnline } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Bousanm | Login";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (username.length > 0 && password.length > 0) {
      setLoading(true);
      try {
        await loginUser(username, password); // Attendre que la connexion soit terminée
      } catch (error) {
        console.error("Erreur de connexion :", error);
      } finally {
        setLoading(false); // Remettre loading à false après la fin du processus
      }
    }
  };

  if (!isOnline) {
    return <Loader />;
  }

  return (
    <section className="bg-light min-vh-100 d-flex flex-column">
      <div className="container-fluid bg-light py-2 d-flex justify-content-center align-items-center" id="navbar">
        <div className="container text-center d-flex justify-content-between align-items-center">
          <div>
            <a href="mailto:zig.bousanm@gmail.com" className="text-muted small">
              <i className="fas fa-envelope text-primary me-1"></i> zig.bousanm@gmail.com
            </a>
          </div>
          <div>
            <a href="#" className="text-muted small me-1">
              <i className="fa fa-clock text-primary me-0"></i> 7/7 | 24/24
            </a>
          </div>
          <div> <a
          href="https://bousanm.com/"
          className="btn btn-primary btn-sm py-0"
        >
          <i className="fas fa-home"></i>
        </a></div>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center align-items-center flex-grow-1">
        <div className="container">
          <div className="text-center mx-auto  wow fadeInUp" style={{ maxWidth: "700px" }}>
            <h3 className=" text-primary">Authentification</h3>
          </div>
          <div className="row g-5 justify-content-center align-items-center">
            <div className="col-xl-5 wow fadeInRight" data-wow-delay="0.4s">
              <a  href="https://bousanm.com"
               className="row justify-content-center align-items-center mb-4">
               
                <img src="/img/logo.png" style={{ width: "90px" }} alt="Logo" />
               
              </a>
              <form onSubmit={handleSubmit}>
                <div className="row gy-4">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nom Utilisateur"
                      id="name"
                      required
                      name="username"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="password"
                      className="form-control"
                      required
                      placeholder="Mot de Passe"
                      id="pass"
                      name="password"
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" disabled={loading && "disabled"} className="btn btn-primary w-100">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Connexion en cours...
                        </>
                      ) : (
                        <>Connecter</>
                      )}
                    </button>
                  </div>
                  <div className="forgot-pwd mt-3">
                    <RouterLink className="pwd" to="/forgot_password">
                      Mot de passe <span>oublié ?</span>
                    </RouterLink>
                                                        <RouterLink className="pwd float-end" to="/register">
                                                         <span className="text-dark"> Pas de compte? </span>Creer un compte !
                                                        </RouterLink>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-xl-5 wow fadeInLeft " data-wow-delay="0.2s">
              <div className="contact-img d-flex justify-content-center mt-3">
                <img
                  src="/img/login-img-1.png"
                  className="img-fluid"
                  alt="Image Contact"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
