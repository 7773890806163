import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { createContext, useState, useEffect, useCallback } from 'react';
import { baseUrlAuth } from './BaseUrl';
const swal = require('sweetalert2');
const AuthContext = createContext();

const baseUrl = baseUrlAuth;

export default AuthContext

export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null
    );
    const [loading, setLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const history = useHistory();

    const checkInternetConnection = useCallback(() => {
        setIsOnline(navigator.onLine);
    }, []);


    const loginUser = async (identifier, password) => {
        if (!isOnline) {
            swal.fire({
                title: "Hors ligne",
                text: "Vous êtes hors connexion. Veuillez vérifier votre réseau.",
                icon: "warning",
                toast: true,
                timer: 3000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
            });
            setLoading(true);
            return;
        }


        try {
            const response = await fetch(`${baseUrl}login/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ identifier, password }),
            });

            const data = await response.json(); // Extraire la réponse JSON
            setLoading(false);

            if (response.status === 200) {
                const user = data.user;
                setAuthTokens(data);
                setUser(user);
                localStorage.setItem(
                    "authTokens", JSON.stringify(data));
                // Redirection en fonction du type d'utilisateur
                if (user.role === "User" || user.role === "TempUser") {
                    history.push("/profile");
                } else {
                    history.push("/dashboard");
                }

                swal.fire({
                    title: "Connexion réussie",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                // Gérer les messages d'erreur
                let errorMessages = data.detail || data.non_field_errors || data.errors;

                // Si `errorMessages` est un objet, le convertir en une chaîne lisible
                if (typeof errorMessages === "object") {
                    errorMessages = Object.values(errorMessages)
                        .flat()
                        .join(", ");
                }

                swal.fire({
                    title: "Erreur de connexion",
                    text: errorMessages || "Une erreur inconnue est survenue.",
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            setLoading(false);

            console.error("Erreur réseau ou serveur :", error);

            swal.fire({
                title: "Erreur réseau",
                text: "Impossible de se connecter au serveur. Vérifiez votre connexion.",
                icon: "error",
                toast: true,
                timer: 3000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };



    const getUserEmailFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.email;
        }
        return null;
    };
    const getUserUsernameFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.username;
        }
        return null;
    };
    const getUserImgFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.image;
        }
        return null;
    };
    const getUserRoleFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.role;
        }
        return null;
    };

    const registerUser = async (full_name, email, username, password, password2) => {
        const response = await fetch(`${baseUrl}register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                full_name, email, username, password, password2
            })
        })
        if (response.status === 201) {
            history.push("/login")
            swal.fire({
                title: "Registration Successful, Login Now",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem("authTokens")
        window.location.href = "https://bousanm.com";
        swal.fire({
            title: "You have been logged out...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        })
    }, [history, setAuthTokens, setUser]);


    // User Actions by Admin
    const createUser = async (formData) => {
        const response = await fetchWithAuth(`${baseUrl}user/`, {
            method: "POST",
            body: formData
        });

        if (response.status === 201) {
            window.location.reload();
            swal.fire({
                title: "User Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const getUser = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await response.json();
        return data;
    }

    const updateUser = async (userId, formData) => {
        const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
            method: "PUT",
            body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
            swal.fire({
                title: "Profil mis à jour avec succès",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });

            const passwordChanged = formData.get("password") !== ""; // Vérifiez si le mot de passe a été modifié
            return { success: true, passwordChanged };
        } else {
            console.log("Problème de serveur");
            swal.fire({
                title: "Une erreur s'est produite : " + response.status + "\n" + data.error,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            return { success: false };
        }
    };


    const deleteUser = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (response.status === 204) {
            swal.fire({
                title: "User Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            //console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const getUserById = async (userId) => {
        const response = await fetchWithAuth(`${baseUrl}user/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await response.json();
        //console.log(data);
        return data;
    }

    const getUsers = async () => {
        const response = await fetchWithAuth(`${baseUrl}user/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data;
    }
    const activateDeactivateUser = async (userId) => {
        try {
            const response = await fetchWithAuth(`${baseUrl}activate-deactivate-user/${userId}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                if (response.status === 403) {
                    swal.fire({
                        title: "Permission Denied: \nYou cannot deactivate your own account.",
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                } else {
                    throw new Error('Failed to activate/deactivate user');
                }
            } else {
                history.push("/users");
                const data = await response.json();
                swal.fire({
                    title: data.message,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error(error);
            swal.fire({
                title: "Failed to activate/deactivate user",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    // End User Actions by Admin
    const resetPassword = async (uidb64, token, newPassword, confirmPassword) => {
        try {
            const response = await fetch(`${baseUrl}reset-password/${uidb64}/${token}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ new_password: newPassword, confirm_password: confirmPassword })
            });
            const data = await response.json();

            if (response.status === 200) {
                swal.fire({
                    title: "Password reset successful",
                    text: data.message,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                history.push("/");
            } else {
                swal.fire({
                    title: "Password reset failed",
                    text: data.error || "An error occurred",
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            swal.fire({
                title: "An error occurred",
                text: "Please try again.",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const fogotPassword = async (email) => {
        try {
            const response = await fetch(`${baseUrl}forgot-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const data = await response.json();
                swal.fire({
                    title: 'Success',
                    text: data.message,
                    icon: 'success',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            } else {
                const errorData = await response.json();
                swal.fire({
                    title: 'Error',
                    text: errorData.message || 'An error occurred. Please try again.',
                    icon: 'error',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            }
        } catch (error) {
            console.error(error);
            swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false
            });
        }
    };
    useEffect(() => {
        window.addEventListener("online", checkInternetConnection);
        window.addEventListener("offline", checkInternetConnection);

        return () => {
            window.removeEventListener("online", checkInternetConnection);
            window.removeEventListener("offline", checkInternetConnection);
        };
    }, [checkInternetConnection]);

    const fetchWithAuth = async (url, options = {}) => {
        try {
            const headers = {
                ...options.headers,
                "Authorization": `Bearer ${authTokens?.access}`,
            };

            // Supprimer l'en-tête 'Content-Type' si body est un FormData
            if (!(options.body instanceof FormData)) {
                headers["Content-Type"] = headers["Content-Type"] || "application/json";
            }

            const response = await fetch(url, {
                ...options,
                headers,
            });

            if (response.status === 401) {
                // Token expiré ou invalide, rafraîchir ou déconnecter l'utilisateur
                await refreshToken();
                // Réessayer la requête après le rafraîchissement du token
                const retryResponse = await fetch(url, {
                    ...options,
                    headers: {
                        ...headers,
                        "Authorization": `Bearer ${authTokens?.access}`,
                    },
                });
                return retryResponse;
            }

            return response;
        } catch (error) {
            console.error("Fetch with auth error:", error);
            throw error;
        }
    };

    const refreshToken = useCallback(async () => {
        try {
            if (!authTokens?.refresh) {
                logoutUser();
                return;
            }
            const response = await fetch(`${baseUrl}token/refresh/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    refresh: authTokens.refresh,
                }),
            });

            const data = await response.json();

            if (response.status === 200) {
                if (typeof data.access === 'string') {
                    setAuthTokens(data);
                    setUser(jwtDecode(data.access));
                    localStorage.setItem("authTokens", JSON.stringify(data));
                } else {
                    throw new Error('Token is not a valid string');
                }
            } else {
                logoutUser();
            }
        } catch (error) {
            console.error("Failed to refresh token", error);
            logoutUser();
        }
    }, [authTokens, logoutUser]);

    const handleInactivity = useCallback(() => {
        logoutUser();
    }, [logoutUser]);

    useEffect(() => {
        if (authTokens) {
            const token = authTokens.access;
            if (token && typeof token === 'string') {
                setUser(jwtDecode(token));
            } else {
                setUser(null);
            }
        }
        setLoading(false);
    }, [authTokens]);

    useEffect(() => {
        const events = ["mousemove", "keydown", "click"];
        let timeout;

        const resetTimeout = () => {
            clearTimeout(timeout);
            timeout = setTimeout(handleInactivity, 60 * 60 * 1000); // Déconnexion après 1 heure d'inactivité
        };

        events.forEach(event => window.addEventListener(event, resetTimeout));

        resetTimeout(); // Initialiser le timeout

        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimeout));
            clearTimeout(timeout);
        };
    }, [handleInactivity]);

    const contextData = {
        user, setUser, authTokens, setAuthTokens, registerUser, loginUser, logoutUser, deleteUser,
        getUsers, getUser, updateUser, createUser, resetPassword, fogotPassword, getUserById,
        activateDeactivateUser, loading, isOnline, getUserEmailFromLocalStorage,
        getUserRoleFromLocalStorage, getUserUsernameFromLocalStorage, getUserImgFromLocalStorage, fetchWithAuth
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}
