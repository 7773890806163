import React, { useContext, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import "./form.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";
import { Link as RouterLink } from "react-router-dom";

export const OnlineRegister = () => {
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [clientCreated, setClientCreated] = useState(false);
  const [clientId, setClientId] = useState("");
  const [loading, setLoading] = useState(false);
  // const [progressPercentage, setProgressPercentage] = useState(0);
  const {
    OnlinecreateAccount,
    OnlinecreateClient,
    getAccountById,
    CancelClientOnline,
    ValidateClientOnline,
  } = useContext(AccountContext);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    sexe: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const calculateProgress = () => {
    const fieldsFilled = Object.keys(formData).filter(
      (key) => formData[key] && formData[key] !== ""
    ).length;
    const totalFields = Object.keys(formData).length;
    return Math.round((fieldsFilled / totalFields) * 100);
  };

  const progressPercentage = calculateProgress();

  const validateStep = async () => {
    const newErrors = {};

    if (!formData.nom) {
      newErrors.nom = "Le champ nom est obligatoire.";
    }
    if (!formData.prenom) {
      newErrors.prenom = "Le champ prénom est obligatoire.";
    }
    if (!formData.tel) {
      newErrors.tel = "Le numéro de téléphone est obligatoire.";
    } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.tel)) {
      newErrors.tel = "Le numéro de téléphone n'est pas valide.";
    }
    if (!formData.email) {
      newErrors.email = "Le champ email est obligatoire.";
    }
    if (!formData.username) {
      newErrors.username = "Le champ username est obligatoire.";
    }
    if (!formData.password) {
      newErrors.password = "Le champ Mot de passe est obligatoire.";
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword =
        "Le champ Confirmer mot de passe est obligatoire.";
    }
    if (!formData.sexe) {
      newErrors.sexe = "Le champ sexe est obligatoire.";
    }

    // Validation de l'email si rempli
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "L'email n'est pas valide.";
    }

    if (!formData.password || formData.password.length < 8)
      newErrors.password =
        "Le mot de passe doit contenir au moins 8 caractères.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";

    return newErrors;
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => {
  //     const updatedData = { ...prevData, [name]: value };
  //     const newErrors = { ...errors };

  //   // Supprime l'erreur du champ si la valeur est valide
  //   if (value.trim() !== "") {
  //     switch (name) {
  //       case "tel":
  //         if (/^\+?[1-9]\d{1,14}$/.test(value)) delete newErrors[name];
  //         else newErrors[name] = "Le numéro de téléphone n'est pas valide.";
  //         break;
  //       case "email":
  //         if (/\S+@\S+\.\S+/.test(value)) delete newErrors[name];
  //         else newErrors[name] = "L'email n'est pas valide.";
  //         break;
  //       case "password":
  //         if (value.length >= 8) delete newErrors[name];
  //         else newErrors[name] =
  //           "Le mot de passe doit contenir au moins 8 caractères.";
  //         break;
  //       case "confirmPassword":
  //         if (value === updatedData.password)
  //           delete newErrors[name];
  //         else
  //           newErrors[name] = "Les mots de passe ne correspondent pas.";
  //         break;
  //       default:
  //         delete newErrors[name]; // Supprime l'erreur pour les champs valides
  //         break;
  //     }
  //   }

  //     // Calculer le montant à toucher si les champs nécessaires sont renseignés
  //     const { nb_main, sol, frequence_paiement, duree_sol } = updatedData;

  //     if (nb_main && sol && frequence_paiement && duree_sol) {
  //       const nbMain = parseFloat(nb_main);
  //       const solValue = parseFloat(sol);
  //       const dureeSol = parseInt(duree_sol, 10);

  //       let montantToucher = 0;

  //       switch (frequence_paiement) {
  //         case "jours":
  //           montantToucher = nbMain * solValue * dureeSol;
  //           break;
  //         case "semaine":
  //           const semaines = Math.ceil(dureeSol / 7);
  //           montantToucher = nbMain * solValue * semaines;
  //           break;
  //         case "mois":
  //           const mois = Math.ceil(dureeSol / 30);
  //           montantToucher = nbMain * solValue * mois;
  //           break;
  //         default:
  //           montantToucher = 0;
  //       }

  //       updatedData.montant_toucher = montantToucher.toFixed(2); // Arrondi à 2 décimales
  //     } else {
  //       updatedData.montant_toucher = ""; // Réinitialise si les valeurs sont incomplètes
  //     }

  //     return updatedData;
  //   });
  // };

  // Étape suivante
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      const newErrors = { ...errors };

      // Validation dynamique et suppression immédiate des erreurs
      if (name === "tel") {
        if (/^\+?[1-9]\d{1,14}$/.test(value)) {
          delete newErrors[name]; // Supprime l'erreur si valide
        } else {
          newErrors[name] = "Le numéro de téléphone n'est pas valide.";
        }
      } else if (name === "email") {
        if (/\S+@\S+\.\S+/.test(value)) {
          delete newErrors[name];
        } else {
          newErrors[name] = "L'email n'est pas valide.";
        }
      } else if (name === "password") {
        if (value.length >= 8) {
          delete newErrors[name];
        } else {
          newErrors[name] =
            "Le mot de passe doit contenir au moins 8 caractères.";
        }
      } else if (name === "confirmPassword") {
        if (value === updatedData.password) {
          delete newErrors[name];
        } else {
          newErrors[name] = "Les mots de passe ne correspondent pas.";
        }
      } else if (value.trim() !== "") {
        delete newErrors[name]; // Supprime l'erreur pour les champs génériques s'ils ne sont pas vides
      }

      // Calculer le montant à toucher si les champs nécessaires sont renseignés
      const { nb_main, sol, frequence_paiement, duree_sol } = updatedData;

      if (nb_main && sol && frequence_paiement && duree_sol) {
        const nbMain = parseFloat(nb_main);
        const solValue = parseFloat(sol);
        const dureeSol = parseInt(duree_sol, 10);

        let montantToucher = 0;

        switch (frequence_paiement) {
          case "jours":
            montantToucher = nbMain * solValue * dureeSol;
            break;
          case "semaine":
            const semaines = Math.ceil(dureeSol / 7);
            montantToucher = nbMain * solValue * semaines;
            break;
          case "mois":
            const mois = Math.ceil(dureeSol / 30);
            montantToucher = nbMain * solValue * mois;
            break;
          default:
            montantToucher = 0;
        }

        updatedData.montant_toucher = montantToucher.toFixed(2); // Arrondi à 2 décimales
      } else {
        updatedData.montant_toucher = ""; // Réinitialise si les valeurs sont incomplètes
      }

      // Met à jour les erreurs dans l'état
      setErrors(newErrors);

      return updatedData;
    });
  };

  const handleSave = async () => {
    // Attendez que la validation soit terminée
    const currentErrors = await validateStep();

    // Vérifiez les erreurs et ne passez pas à l'étape suivante si des erreurs existent
    if (Object.keys(currentErrors).length === 0) {
      const {
        nom,
        prenom,
        tel,
        email,
        sexe,
        username,
        password,
        confirmPassword,
      } = formData;

      let formData1 = new FormData();
      formData1.append("nom", nom);
      formData1.append("prenom", prenom);
      formData1.append("email", email);
      formData1.append("tel", tel);
      formData1.append("sexe", sexe);
      formData1.append("username", username);
      formData1.append("password", password);
      formData1.append("confirmPassword", confirmPassword);

      try {
        setLoading(true); // Activer le loader
        // console.log("Envoi des données à OnlinecreateClient:", formData1);
        const response = await OnlinecreateClient(formData1);
        if (response) {
          formData1.append("client", response);
          formData1.append("type_compte", "BS");
          formData1.append("devise", "HTG");
          const response1 = await OnlinecreateAccount(formData1);
          if (response1.id) {
            await ValidateClientOnline(clientId);
            setFormData({});
          } else {
            // Swal.fire("Erreur", "Une erreur est survenue lors de la création du compte.", "error");
          }
          setStep(step + 1);
          // console.log("Client créé avec ID:", response);
        } else {
          console.error("Erreur: Aucun ID retourné par le backend");
        }
      } catch (error) {
        console.error("Erreur lors de la création du client:", error);
        setErrors({ general: "Erreur lors de l'enregistrement du client" });
      } finally {
        setLoading(false); // Désactiver le loader
      }
    } else {
      // Afficher les erreurs si elles existent
      setErrors(currentErrors);
    }
  };

  return (
    
    <section className="bg-light min-vh-100 d-flex flex-column">
      <div className="container-fluid bg-light py-2 d-flex justify-content-center align-items-center" id="navbar">
        <div className="container text-center d-flex justify-content-between align-items-center">
          <div>
            <a href="mailto:zig.bousanm@gmail.com" className="text-muted small">
              <i className="fas fa-envelope text-primary me-1"></i> zig.bousanm@gmail.com
            </a>
          </div>
          <div>
            <a href="#" className="text-muted small me-1">
              <i className="fa fa-clock text-primary me-0"></i> 7/7 | 24/24
            </a>
          </div>
          <div> <a
          href="https://bousanm.com/"
          className="btn btn-primary btn-sm py-0"
        >
          <i className="fas fa-home"></i>
        </a></div>
        </div>
      </div>

      {/*  Contact Start */}
      <div className="container-fluid contact bg-light " id="contact">
        <div className="container py-5">
          
          <div
            className="text-center mx-auto pb-2 pt-2 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: "800px" }}
          >
            <h3 className="d text-primary">Inscription </h3>
          </div>
          <div className="row g-5">
            <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <div>
                  {/* Form */}
                  <div class="card p-4">
                    {/* Barre de progression */}
                    <div className="row g-2">
                      <div className="col-md-12">
                        <div className="progress-container d-flex align-items-center">
                          <div
                            className="progress flex-grow-1 progress-bar-animated"
                            style={{ height: "10px" }}
                          >
                            <div
                              className="progress-bar progress-bar-animated bg-primary"
                              role="progressbar"
                              style={{ width: `${progressPercentage}%` }}
                              aria-valuenow={progressPercentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {/* Texte optionnel, rendu invisible */}
                              <span className="visually-hidden">
                                {progressPercentage}%
                              </span>
                            </div>
                          </div>
                          {/* Texte de progression */}
                        </div>
                      </div>
                      <div
                        className="col-md-12 d-flex justify-content-end"
                        style={{ marginTop: -10 }}
                      >
                        <p className="progress-text mb-4">
                          {progressPercentage}% complété
                        </p>
                      </div>
                    </div>
                    {/* Barre de progression */}

                    {step === 1 && (
                      <div className="row g-2">

                        <div className="col-md-6">
                          <input
                            type="text"
                            name="nom"
                            placeholder="Nom"
                            value={formData.nom}
                            onChange={handleChange}
                            id="validationDefault01"
                            className={`form-control form-control-sm ${
                              errors.nom && "is-invalid"
                            }`}
                          />
                          {/* Message d'erreur */}
                          <span className="invalid-feedback">
                            {errors.nom ? errors.nom : <br />}
                          </span>
                        </div>

                        <div className="col-md-6">
                          <input
                            type="text"
                            name="prenom"
                            placeholder="Prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                            id="validationDefault02"
                            className={`form-control form-control-sm  ${
                              errors.prenom && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.prenom ? errors.prenom : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`form-control form-control-sm  ${
                              errors.email && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.email ? errors.email : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <PhoneInput
                            inputStyle={{ width: "100%", height: "100%" }}
                            className="phone "
                            placeholder="Téléphone"
                            country={"ht"}
                            value={formData.tel}
                            id="validationDefault03"
                            onChange={(phone) => {
                              // Mise à jour de `formData`
                              setFormData((prevData) => ({
                                ...prevData,
                                tel: phone,
                              }));

                              // Validation immédiate de `tel`
                              const isValid = /^\+?[1-9]\d{1,14}$/.test(phone);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                tel: isValid
                                  ? null
                                  : "Le numéro de téléphone n'est pas valide.",
                              }));
                            }}
                            inputClass={`form-control form-control-sm ${
                              errors.tel ? "is-invalid" : ""
                            }`}
                          />
                          <span className="error">
                            {errors.tel ? errors.tel : <br />}
                          </span>
                        </div>

                        <div className="col-md-6">
                          <select
                            className={`form-control form-control-sm  ${
                              errors.sexe && "is-invalid"
                            }`}
                            id="validationDefault04"
                            name="sexe"
                            value={formData.sexe}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Sexe...
                            </option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                          </select>
                          <span className="error">
                            {errors.sexe ? errors.sexe : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="username"
                            placeholder="Nom Utilisateur"
                            value={formData.username}
                            onChange={handleChange}
                            className={`form-control form-control-sm ${
                              errors.username && "is-invalid"
                            }`}
                            id="validationDefault08"
                          />
                          <span className="error">
                            {errors.username ? errors.username : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={formData.password}
                            onChange={handleChange}
                            className={`form-control form-control-sm ${
                              errors.password && "is-invalid"
                            }`}
                            id="validationDefault08"
                          />
                          <span className="error">
                            {errors.password ? errors.password : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirmer Mot de passe"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            className={`form-control form-control-sm ${
                              errors.confirmPassword && "is-invalid"
                            }`}
                            id="validationDefault08"
                          />
                          <span className="error">
                            {errors.confirmPassword ? (
                              errors.confirmPassword
                            ) : (
                              <br />
                            )}
                          </span>
                        </div>
                        <div className="col-md-12">
                          <button
                            style={{ width: "100%" }}
                            type="submit"
                            className="btn btn-primary btn-sm"
                            onClick={handleSave}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                                Validation en cours...
                              </>
                            ) : (
                              <>
                                <i className="fas fa-save me-2"></i>Enregistrer
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                                    <div className="forgot-pwd mt-1 fw-bold text-end">
                                      <RouterLink className="pwd" to="/">
                                        Vous <span>connecter !</span>
                                      </RouterLink>
                                    </div>
                  {/* End Form */}
                </div>
              </div>
            </div>
            <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="contact-img d-flex justify-content-center">
                <div className="contact-img-inner">
                  <img
                    src="/img/login-img-1.png"
                    className="img-fluid w-100"
                    alt="Image Contact"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
