import AuthContext from "../context/AuthContext";
import { CustomerContext } from "../context/CustomerContext";
import { AccountContext } from "../context/AccountsContext";
import './form.css'

import { useState, useContext, useEffect } from "react";

const FormCustomers = ({ customerId }) => {
  const { updateClient, getClientUpdatById } = useContext(AccountContext);
  const { logoutUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [customerData, setCustomerData] = useState({
    nom: "",
    prenom: "",
    tel: "",
    sexe: "",
    date_naissance: "",
    lieu_naissance: "",
    email: "",
    adresse: "",
    statut: "",
    username: "",
  });

  const [initialData, setInitialData] = useState({});

  // Récupérer les détails du client lors du chargement du composant
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const data = await getClientUpdatById(customerId);
        setCustomerData(data);
        setInitialData(data); // Sauvegarder les données initiales pour comparaison
      } catch (error) {
        console.error("Erreur lors du chargement des détails du client :", error);
      }
    };

    if (customerId) {
      fetchClientDetails();
    }
  }, [customerId, getClientUpdatById]);

  // Gestion des changements des inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(customerData).forEach((key) => {
      formData.append(key, customerData[key]);
    });
  
    setLoading(true);
    setErrors({});
  
    try {
      const result = await updateClient(customerId, formData);
  
      if (result.success) {
        // Si la mise à jour est réussie, procéder comme prévu
        alert("Client mis à jour avec succès !");
        
        // Vérifiez si l'email ou le username a changé et déconnectez l'utilisateur si nécessaire
        if (
          customerData.email !== initialData.email ||
          customerData.username !== initialData.username
        ) {
          await logoutUser();
        }
      } else {
        // Si des erreurs sont présentes, les afficher dans l'état des erreurs
        setErrors(result.errors);
      }
    } catch (err) {
      console.error("Erreur lors de la mise à jour du client :", err);
      setErrors({ general: "Une erreur inattendue est survenue." });
    } finally {
      setLoading(false);
    }
  };
  
  if (!customerData || customerData.nom === "") {
    return <div>Chargement des données du client...</div>;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample"
      encType="multipart/form-data"
    >
      <div className="row">
        {[
          { label: "Nom", name: "nom", type: "text", required: true },
          { label: "Prénom", name: "prenom", type: "text", required: true },
          { label: "Téléphone", name: "tel", type: "number", required: true },
          { label: "Date de Naissance", name: "date_naissance", type: "date", required: true },
          { label: "Lieu de Naissance", name: "lieu_naissance", type: "text", required: true },
          { label: "Email", name: "email", type: "email", required: true },
          { label: "Adresse", name: "adresse", type: "text", required: true },
          { label: "Statut", name: "statut", type: "text", required: true },
          { label: "Nom Utilisateur", name: "username", type: "text", required: true },
        ].map(({ label, name, type, required }) => (
          <div className="col-md-4" key={name}>
            <div className="form-group">
              <label htmlFor={name}>{label}</label>
              <input
                type={type}
                name={name}
                className={`form-control form-control-sm ${
                  errors[name] ? "is-invalid" : ""
                }`}
                id={name}
                placeholder={label}
                required={required}
                value={customerData[name] || ""}
                onChange={handleChange}
              />
              {errors[name] && (
                <div className="invalid-feedback">{errors[name]}</div>
              )}
            </div>
          </div>
        ))}

        {/* Sexe */}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="sexe">Sexe</label>
            <select
              className={`form-select form-select-sm ${
                errors.sexe ? "is-invalid" : ""
              }`}
              name="sexe"
              id="sexe"
              required
              value={customerData.sexe || ""}
              onChange={handleChange}
            >
              <option value="">Choisir sexe</option>
              <option value="F">Féminin</option>
              <option value="M">Masculin</option>
            </select>
            {errors.sexe && (
              <div className="invalid-feedback">{errors.sexe}</div>
            )}
          </div>
        </div>

        {/* Bouton Enregistrer */}
        <div className="col-md-8 mt-5 text-center">
          <button
            type="submit"
            className="btn btn-primary btn-sm float-end"
            disabled={loading}
          >
            {loading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i> En cours...
              </>
            ) : (
              <>
                <i className="fas fa-save"></i> Enregistrer
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormCustomers;

const FormCustomersMutuelle = () => {
  const { updateClientMutuelle } = useContext(CustomerContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const tel = e.target.tel.value;
    const username = e.target.username.value;
    const password = e.target.password.value;
    const mutuelle = e.target.mutuelle.value;
    const duree = e.target.duree.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("tel", tel);
    formData.append("password", password);
    formData.append("username", username);
    formData.append("mutuelle", mutuelle);
    formData.append("duree", duree);
    formData.append("created_by", user.user_id);

    setLoading(true); // Activer le loader avant de commencer
    try {
      if (nom.length > 0) {
        await updateClientMutuelle(formData); // Attendre que la fonction se termine
      }
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Désactiver le loader une fois terminé (succès ou échec)
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample "
      encType="multipart/form-data"
    >
      <div className="row">
        <>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                placeholder="Nom"
                required
                name="nom"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="name">Prenom</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                placeholder="Prenom"
                required
                name="prenom"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Télephone</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="exampleInputtel3"
                placeholder="Télephone"
                required
                name="tel"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Mutuelle | Montant/Jours</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="exampleInputtel3"
                placeholder="Mutuelle | Montant/Jours"
                required
                name="mutuelle"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Durée</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="exampleInputtel3"
                placeholder="Durée"
                required
                name="duree"
              />
            </div>
          </div>
        </>
        {/* )} */}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="name">Nom Utilisateur</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="name"
              placeholder="Nom Utilisateur"
              name="username"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="exampleInputtel3">Mot de passe</label>
            <input
              type="email"
              className="form-control form-control-sm"
              id="exampleInputtel3"
              placeholder="Mot de passe"
              name="password"
            />
          </div>
        </div>
        <div className="col-md-12 mt-4 text-center">
          <div className="form-group pt-1">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> En cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { FormCustomers, FormCustomersMutuelle };
