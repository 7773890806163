/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Include/Sidebar";
import Footer from "../Include/Footer";
import { useTimer } from "../context/TimerContext";
import { TransactionsContext } from "../context/TransactionsContext";
import { DataDashboard, DataRapportSol } from "../data/DataTransactions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TransactionSolContext } from "../context/TransactionSolContext";
import NavbarAdmin from "../Include/NavbarAdmin";
import { AccountContext } from "../context/AccountsContext";

function Dashboard() {
  // const { timeLeft } = useTimer();
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

const {getTotalJours}=useContext(AccountContext);
  const {
    getTotalAccounts,
    getAccountsByType,
    getTotalUsers,
    getCustomerSolTotal,
    getNbrMainSolTotal,
    getAccountsStatus,
    getRecentAccounts,
    getTotalBalance,
  } = useContext(TransactionsContext);
  const {
    getMontantTotalDuJour,
    getMontantTotalEntree,
    getMontantJoursPrecedents,
  } = useContext(TransactionSolContext);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [CustomerSolTotal, setCustomerSolTotal] = useState(0);
  const [NbrMainSolTotal, setNbrMainSolTotal] = useState(0);
  const [accountsByType, setAccountsByType] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [accountsStatus, setAccountsStatus] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);

  // Déclarez des états pour stocker les montants
  const [montantTotalDuJour, setMontantTotalDuJour] = useState(0);
  const [montantTotalEntree, setMontantTotalEntree] = useState(0);
  const [montantJoursPrecedents, setMontantJoursPrecedents] = useState(0);

  const [totals, setTotals] = useState({
    total_jours: 0,
    total_non_paye: 0,
    montant_total: 0,
});
  const formatTime = ({ hours, minutes, seconds }) => {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
  };

  useEffect(() => {
    const fetchData = async () => {
      document.title = "Bousanm | Dashboard";
      try {
        setLoading(true);
        const totalAccountsData = await getTotalAccounts();
        setLoading(false);
        setTotalAccounts(totalAccountsData.total_accounts);

        setLoading(true);
        const CustomerSolTotalData = await getCustomerSolTotal();
        setLoading(false);
        setCustomerSolTotal(CustomerSolTotalData.total_customerSol);

        setLoading(true);
        const NbrMainSolTotalData = await getNbrMainSolTotal();
        setLoading(false);
        setNbrMainSolTotal(NbrMainSolTotalData.total_customerSol);
        console.log(NbrMainSolTotalData);

        setLoading(true);
        const accountsByTypeData = await getAccountsByType();
        setLoading(false);
        setAccountsByType(accountsByTypeData);

        setLoading(true);
        const totalUsersData = await getTotalUsers();
        setLoading(false);
        setTotalUsers(totalUsersData.total_users);

        setLoading(true);
        const accountsStatusData = await getAccountsStatus();
        setLoading(false);
        setAccountsStatus(accountsStatusData);

        setLoading(true);
        const totalBalanceData = await getTotalBalance();
        setLoading(false);
        setTotalBalance(totalBalanceData);
        setLoading(true);
        const response = await getTotalJours();
        console.log("tot:",response);
        
        setTotals(response);  // Met à jour l'état avec les données reçues
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    const fetchMontants = async () => {
      // Récupérer les montants et mettre à jour les états

      setLoading(true);
      // const montantJour = await getMontantTotalDuJour();
      // const montantEntree = await getMontantTotalEntree();
      // const montantPrecedents = await getMontantJoursPrecedents();
      setLoading(false);

      // setMontantTotalDuJour(montantJour);
      // setMontantTotalEntree(montantEntree);
      // setMontantJoursPrecedents(montantPrecedents);
    };

    fetchMontants();
    fetchData();
  }, [
    getTotalAccounts,
    getAccountsByType,
    getTotalUsers,
    getCustomerSolTotal,
    getNbrMainSolTotal,
    getAccountsStatus,
    getRecentAccounts,
    getTotalBalance,
    getMontantTotalDuJour,
    getMontantTotalEntree,
    getMontantJoursPrecedents,
  ]);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const getLastUpdateDate = () => {
    const date = new Date();
    return formatDate(date);
  };
  return (

    <>
    
        {/* <NavbarAdmin
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        /> */}
        {/* Passer la fonction toggleSidebar au Sidebar */}
       
        <Sidebar />

        <main className="app-main">
        
        <div className="app-content-header">
         
         <div className="container-fluid">
           
           <div className="row">
             <div className="col-sm-6"><h3 className="mb-0">Tableau de bord</h3></div>
             <div className="col-sm-6">
               <ol className="breadcrumb float-sm-end">
                 <li className="breadcrumb-item"><a href="#"></a></li>
                 <li className="breadcrumb-item active" aria-current="page">Tableau de bord</li>
               </ol>
             </div>
           </div>
           
         </div>
         
       </div>
     
       <div className="app-content">
         
          <div className="container-fluid">
          
            <div className="row">
             
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-info elevation-1">
                    <i className="fas fa-cog"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">PLACEMENTS</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {accountsByType.PL_accounts} <small> Placement</small>
                        </>
                      )}
                    </span>
                    <span className="info-box-number pb-0">
                      <small className="mb-0 ">Mise à jours le</small>
                      <br />
                      <p className="mb-0 mt-0 text-info">
                        {getLastUpdateDate()}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 ">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-danger elevation-1">
                    <i className="fas fa-thumbs-up"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">BOUSANM</span>
                    <span className="info-box-number">
                      <Link to="/List">
                        {loading ? (
                          <>
                            <i className="fas fa-spinner fa-spin"></i>{" "}
                          </>
                        ) : (
                          <>
                            {accountsByType.BS_accounts} <small>Comptes </small>
                          </>
                        )}
                      </Link>
                    </span>
                    <span className="info-box-number pb-0">
                      <small className="mb-0 fw-14">Mise à jours le </small>
                      <br />
                      <p className="mb-0 mt-0 text-danger">
                        {getLastUpdateDate()}
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-success elevation-1">
                    <i className="fas fa-shopping-cart"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">PERSONNELS</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {totalUsers} <small> Utilisateurs</small>
                        </>
                      )}
                    </span>
                    <span className="info-box-number pb-0">
                      <small className="mb-0 ">Mise à jours le</small>
                      <br />
                      <p className="mb-0 mt-0 text-success">
                        {getLastUpdateDate()}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-warning elevation-1">
                    <i className="fas fa-users"></i>
                  </span>
                  <div className="info-box-content">
                    <span className="info-box-text">COMPTES</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>
                          {totalAccounts} <small> Comptes</small>
                        </>
                      )}
                    </span>
                    <span className="info-box-number pb-0 ">
                      <small className="mb-0 ">Mise à jours le</small>
                      <br />
                      <p className="mb-0 mt-0 text-warning">
                        {getLastUpdateDate()}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className="row mb-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Rapport | Jours</h5>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-tool dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          <i className="fas fa-wrench"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          role="menu"
                        >
                          <a href="#" className="dropdown-item">
                            Action
                          </a>
                          <a href="#" className="dropdown-item">
                            Another action
                          </a>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <DataRapportSol />
                  </div>
                  <div className="card-footer">
                    <div className="row p-1">
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success">
                            <i className="fas fa-caret-up"></i> +
                          </span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {totals.total_jours} <small> Gdes</small>
                              </>
                            )}
                          </h5>
                          <span className="description-text">
                            Total Du Jours
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-warning">
                            <i className="fas fa-caret-left"></i> -
                          </span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {totals.total_non_paye}
                                <small> Gdes</small>
                              </>
                            )}
                          </h5>
                          <span className="description-text">
                            Total Non Payé
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success">
                            <i className="fas fa-caret-up"></i> *
                          </span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {totals.montant_total}
                                <small> Gdes</small>
                              </>
                            )}
                          </h5>
                          <span className="description-text">
                            Montant Total
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block">
                          <span className="description-percentage text-danger">
                            <i className="fas fa-caret-down"></i> -
                          </span>
                          <h5 className="description-header">
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                              </>
                            ) : (
                              <>
                                {/* {studentCountByNiveau.NSIV} /} */}
                                <small> Gdes</small>
                              </>
                            )}
                          </h5>
                          <span className="description-text">
                            Montant Total Retiré
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-8">
                <div className="row"></div>

                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Dernière Enregistrements</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <DataDashboard />
                    </div>
                  </div>
                  <div className="card-footer clearfix">
                    <Link
                      to="/add-account"
                      className="btn btn-sm btn-info float-left"
                    >
                      Nouveau Compte
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="info-box mb-3 bg-warning">
                  <span className="info-box-icon">
                    <i className="fas fa-tag"></i>
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">Temps restant : </span>
                    <span className="info-box-number">
                      {/* {formatTime(timeLeft)} */}
                    </span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-success">
                  <span className="info-box-icon">
                    <i className="far fa-heart"></i>
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">Compte Actif</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{accountsStatus.active_accounts}</>
                      )}
                    </span>
                  </div>
                </div>

                <div className="info-box mb-3 bg-danger">
                  <span className="info-box-icon">
                    <i className="fas fa-cloud-download-alt"></i>
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">Compte Inactif</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{accountsStatus.inactive_accounts}</>
                      )}
                    </span>
                  </div>
                </div>

                <div className="info-box mb-3 bg-info">
                  <span className="info-box-icon">
                    <i className="far fa-comment"></i>
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">Total Plasman</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{totalBalance.total_balance}</>
                      )}
                    </span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-secondary">
                  <span className="info-box-icon">
                    <i className="far fa-comment"></i>
                  </span>

                  <div className="info-box-content">
                    <span className="info-box-text">Total Bousanm</span>
                    <span className="info-box-number">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                        </>
                      ) : (
                        <>{totalBalance.total_balance}</>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
     </>
  );
}

export default Dashboard;