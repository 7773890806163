import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { DataHistory } from "../../data/DataAccounts";

const HistoryTransactions = () => {
  return (
    <>
      <Sidebar />

      <main className="app-main">
        <div className="app-content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">Dashboard</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right py-0 px-4">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tablau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/transaction">Transactions</Link>
                  </li>
                  <li className="breadcrumb-item">Historique</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="app-content">
          <div className="container-fluid">
            <div className="card p-4">
              <div className="row mb-4">
                <div className="col-10">
                  <h2 className="">Liste des Transactions Effectuer</h2>
                </div>
                <div className="col d-flex justify-content-end"></div>
              </div>
              <DataHistory />
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </main>
    </>
  );
};

export default HistoryTransactions;
