/* eslint-disable */
import React, { createContext, useContext } from "react";
import AuthContext from "./AuthContext";
import { useHistory } from "react-router-dom";
import { baseUrlAccount, baseUrlAccount1 } from "./BaseUrl";
const swal = require("sweetalert2");

const baseUrl = baseUrlAccount;
const baseUrl1 = baseUrlAccount1;

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authTokens, logoutUser,fetchWithAuth } = useContext(AuthContext);

  const history = useHistory();

  const getAccounts = async () => {
    const response = await fetchWithAuth(`${baseUrl1}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getAccountsValidate = async () => {
    const response = await fetchWithAuth(`${baseUrl}client-temp`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getAccountById = async (AccountId) => {
    const response = await fetchWithAuth(`${baseUrl1}${AccountId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getClientById = async (AccountId) => {
    const response = await fetchWithAuth(`${baseUrl1}${AccountId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getAccountByTel = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}search-account/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const getAccountByCode = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl1}detail`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const getAccountDetailByCode = async (code) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}account/details/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ code }),
      });

      if (response.status === 200) {
        const data = await response.json();
        //console.log("data Context"+data);
        
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  const getAccountIdByUsername = async (username) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}client-id/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify({ username }),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  const getAccountCodeListById = async (client_id) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${client_id}/comptes/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };

  const deleteAccounts = async (AccountId) => {
    const response = await fetchWithAuth(`${baseUrl}${AccountId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const ResetPasswordCustomer = async (code) => {
    const response = await fetchWithAuth(`${baseUrl}reset-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    });
    if (response.status === 204) {
      swal.fire({
        title: "Réinisialié",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const updateAccounts = async (AccountId, formData) => {
    const response = await fetchWithAuth(`${baseUrl}${AccountId}/`, {
      method: "PATCH",
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Account Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createAccount = async (formData) => {
    try {
      // Appel pour créer le compte
      const response = await fetchWithAuth(`${baseUrl1}`, {
        method: "POST",
        body: formData,
      });

      if (response.status === 201) {
        const accountData = await response.json(); // Obtenir les infos du compte créé (email, username, password)

        // Afficher un message de succès
        swal.fire({
          title: "Compte créé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        // Appel API pour envoyer l'email
        const emailResponse = await fetch(`${baseUrl}send-account-email/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: accountData.email,
            username: accountData.username,
            password: accountData.pass_send,
          }),
        });
        
        //console.log("Réponse de l'API email:", emailResponse);
        
        if (emailResponse.status === 200) {
          const responseData = await emailResponse.json();
          console.log("Données de la réponse email:", responseData);
        
          swal.fire({
            title: "E-mail envoyé avec succès",
            icon: "success",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          console.error("Erreur lors de l'envoi de l'e-mail:", emailResponse.status);
          swal.fire({
            title: "Erreur lors de l'envoi de l'e-mail",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
        
        // Rediriger vers la liste après la création
        history.push("/List");
      } else {
        console.error("Erreur lors de la création du compte:", response.status);
        swal.fire({
          title: "Une erreur est survenue: " + response.status,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la création du compte:", error);
      swal.fire({
        title: "Erreur lors de la création du compte",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  // const createClient = async (formData) => {
  //   try {
  //     // Appel pour créer le compte
  //     const response = await fetchWithAuth(`${baseUrl}`, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (response.status === 201) {
  //       const accountData = await response.json(); // Obtenir les infos du compte créé (email, username, password)
  //       // Afficher un message de succès
  //       swal.fire({
  //         title: "Compte créé avec succès",
  //         icon: "success",
  //         toast: true,
  //         timer: 3000,
  //         position: "top-right",
  //         timerProgressBar: true,
  //         showConfirmButton: false,
  //       });
  //       console.log(accountData);
        
  //       return accountData;
        
  //       // Rediriger vers la liste après la création
  //       // history.push("/List");
  //     } else {
  //       console.error("Erreur lors de la création du compte:", response.status);
  //       swal.fire({
  //         title: "Une erreur est survenue: " + response.status,
  //         icon: "error",
  //         toast: true,
  //         timer: 3000,
  //         position: "top-right",
  //         timerProgressBar: true,
  //         showConfirmButton: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la création du compte:", error);
  //     swal.fire({
  //       title: "Erreur lors de la création du compte",
  //       icon: "error",
  //       toast: true,
  //       timer: 3000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //   }
  // };

  const createClient = async (formData) => {
    try {
      // Appel pour créer le compte
      const response = await fetchWithAuth(`${baseUrl}`, {
        method: "POST",
        body: formData,
      });
  
      if (response.status === 201) {
        const accountData = await response.json(); // Obtenir les infos du compte créé (email, username, password)
  
        // Afficher un message de succès
        swal.fire({
          title: "Compte créé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
  
        //console.log(accountData);
        return { success: true, data: accountData }; // Retourner les données du compte créé
      } else {
        const errorData = await response.json(); // Obtenir les détails de l'erreur
        console.error("Erreur lors de la création du compte:", response.status, errorData);
  
        // Afficher un message d'erreur
        swal.fire({
          title: "Une erreur est survenue",
          text: errorData.message || `Erreur: ${response.status}`,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
  
        return { success: false, error: errorData }; // Retourner les détails de l'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la création du compte:", error);
  
      // Afficher un message d'erreur
      swal.fire({
        title: "Erreur lors de la création du compte",
        text: error.message,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
  
      return { success: false, error: { message: error.message } }; // Retourner les détails de l'erreur
    }
  };

  const updateClient = async (AccountId, formData) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${AccountId}/update/`, {
        method: "PATCH",
        body: formData,
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.status === 200) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        window.location.reload();
        return { success: true }; // Retourne un objet de succès
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        return { success: false, errors: responseData }; // Retourner les erreurs
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
    
  
      return { success: false, errors: { general: error.message } }; // Retourner une erreur générale
    }
  };
  
  const updateImageClient = async (AccountId, formData) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${AccountId}/update-image/`, {
        method: "PATCH",
        body: formData,
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.ok) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        window.location.reload();
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        swal.fire({
          title: "An Error Occurred",
          text: errors || "Please check the form and try again.",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
      swal.fire({
        title: "An Unexpected Error Occurred",
        text: error.message || "Something went wrong. Please try again later.",
        icon: "error",
        toast: true,
        timer: 5000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const ValidateClient = async (AccountId) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${AccountId}/validate/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.ok) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        window.location.reload();
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        swal.fire({
          title: "An Error Occurred",
          text: errors || "Please check the form and try again.",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
      swal.fire({
        title: "An Unexpected Error Occurred",
        text: error.message || "Something went wrong. Please try again later.",
        icon: "error",
        toast: true,
        timer: 5000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const ValidateClientOnline = async (AccountId) => {
    try {
      const response = await fetch(`${baseUrl}${AccountId}/validate-online/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.ok) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        window.location.reload();
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        swal.fire({
          title: "An Error Occurred",
          text: errors || "Please check the form and try again.",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
      swal.fire({
        title: "An Unexpected Error Occurred",
        text: error.message || "Something went wrong. Please try again later.",
        icon: "error",
        toast: true,
        timer: 5000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const CancelClientOnline = async (AccountId) => {
    try {
      const response = await fetch(`${baseUrl}${AccountId}/cancel-online/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.ok) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        swal.fire({
          title: "An Error Occurred",
          text: errors || "Please check the form and try again.",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
      swal.fire({
        title: "An Unexpected Error Occurred",
        text: error.message || "Something went wrong. Please try again later.",
        icon: "error",
        toast: true,
        timer: 5000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const CancelClient = async (AccountId) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${AccountId}/cancel/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const responseData = await response.json(); // Parse la réponse JSON
  
      if (response.ok) {
        swal.fire({
          title: "Client Updated Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        // Récupérer les messages d'erreur du backend
        const errors = Object.entries(responseData)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
  
        swal.fire({
          title: "An Error Occurred",
          text: errors || "Please check the form and try again.",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      // Gestion des erreurs réseau ou autres erreurs inattendues
      swal.fire({
        title: "An Unexpected Error Occurred",
        text: error.message || "Something went wrong. Please try again later.",
        icon: "error",
        toast: true,
        timer: 5000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getClientUpdatById = async (AccountId) => {
    const response = await fetchWithAuth(`${baseUrl}${AccountId}/update/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  
  const OnlineRegister = async (formData) => {
    try {
      // Appel pour créer le compte
      const response = await fetch(`${baseUrl}register/`, {
        method: "POST",
        body: formData,
      });

      if (response.status === 201) {
        const accountData = await response.json(); // Obtenir les infos du compte créé (email, username, password)

        // Afficher un message de succès
        swal.fire({
          title: "Compte créé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        // Rediriger vers la liste après la création
        history.push("/login");
      } else {
        console.error("Erreur lors de la création du compte:", response.status);
        swal.fire({
          title: "Une erreur est survenue: " + response.status,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la création du compte:", error);
      swal.fire({
        title: "Erreur lors de la création du compte",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const OnlinecreateAccount = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}temp-compte/`, {
        method: "POST",
        body: formData,
      });
  
      if (response.status === 201) {
        const accountData = await response.json(); // Obtenir les infos du compte créé
        swal.fire({
          title: "Compte créé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/login");
        return { success: true, data: accountData };
      } else {
        const errorData = await response.json(); // Obtenir les erreurs du backend
        return { success: false, errors: errorData }; // Renvoyer les erreurs
      }
    } catch (error) {
      console.error("Erreur lors de la création du compte:", error);
      return { success: false, errors: { message: "Une erreur inattendue est survenue." } }; // Renvoyer un message d'erreur générique
    }
  };

  const OnlinecreateClient = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}temp-client/`, {
        method: "POST",
        body: formData,
      });
  
      if (response.status === 201) {
        const clientData = await response.json(); // Obtenir les infos du client créé
        // swal.fire({
        //   title: "Compte client créé avec succès",
        //   icon: "success",
        //   toast: true,
        //   timer: 3000,
        //   position: "top-right",
        //   timerProgressBar: true,
        //   showConfirmButton: false,
        // });
        return clientData.id
      } else {
        const errorData = await response.json(); // Obtenir les erreurs du backend
        return { success: false, errors: errorData }; // Renvoyer les erreurs
      }
    } catch (error) {
      console.error("Erreur lors de la création du client:", error);
      return { success: false, errors: { message: "Une erreur inattendue est survenue." } }; // Renvoyer un message d'erreur générique
    }
  };

  const activateDeactivateAccount = async (userId) => {
    try {
      const response = await fetchWithAuth(
        `${baseUrl}activate-deactivate-accounts/${userId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own account.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const ValidateOnlineAccount = async (client_id,account_id,user_id) => {
    try {
      const response = await fetchWithAuth(
        `${baseUrl}${client_id}/${account_id}/validate-client-online/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({created_by: user_id }),
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own account.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const copyAccount = async (formData) => {
    const response = await fetchWithAuth(`${baseUrl}copy-account/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Account Copied Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      //console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getClientAccounts = async (email) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}client-accounts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  const changePassword = async (email, newPassword) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, new_password: newPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        await logoutUser();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      swal.fire({
        title: "Failed to change password",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const changePasswordCustomer = async (
    username,
    new_password,
    confirm_new_password
  ) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}change-password-customer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, new_password, confirm_new_password }),
      });

      if (response.ok) {
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        await logoutUser();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      swal.fire({
        title: "Failed to change password",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const fetchNextInterest = async (accountId) => {
    try {
      const response = await fetchWithAuth(`${baseUrl1}calculate_plasman_interest/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ account: accountId }), // Envoyez l'ID du compte
      });
      const data = await response.json();
      //console.log("API Response:", data); // Ajoutez ceci pour vérifier la réponse
      if (data.nextInterest) {
        return data.nextInterest;
      } else {
        console.error("Error calculating interest:", data.error);
        return null;
      }
    } catch (error) {
      console.error("Error fetching next interest:", error);
      return null;
    }
  };

  const getRapportsJours = async () => {
    const response = await fetchWithAuth(`${baseUrl}rapport/sol/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getTotalJours = async () => {
    const response = await fetchWithAuth(`${baseUrl}sol-account-totals/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  // Ajoutez cette fonction à `contextData` dans votre `AccountProvider`
  const contextData = {
    createAccount,
    getAccounts,
    getAccountById,
    updateAccounts,
    deleteAccounts,
    getAccountByTel,
    getAccountByCode,
    activateDeactivateAccount,
    copyAccount,
    getClientAccounts,
    fetchNextInterest,
    changePassword,
    OnlineRegister,
    changePasswordCustomer,
    ResetPasswordCustomer,
    createClient,
    getClientById,
    getAccountDetailByCode,
    getAccountIdByUsername,
    getAccountCodeListById,
    updateClient,
    getClientUpdatById,
    updateImageClient,
    ValidateClient,
    OnlinecreateAccount,
    OnlinecreateClient,CancelClient,
    CancelClientOnline,ValidateClientOnline,
    getAccountsValidate,ValidateOnlineAccount,
    getRapportsJours,getTotalJours,
  };

  return (
    <AccountContext.Provider value={contextData}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
