import React from 'react'
import Footer from '../../Include/Footer'
import Sidebar from '../../Include/Sidebar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { DataNearEndDate } from '../../data/DataTransactions'

function Renewal() {
    return (
        <>
            <Sidebar />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <div className="ml-2">
                                    <h3 className="display fw-500 fw-bold">Gestion de renouvellements</h3>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Tableau de Bord</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/transaction">Liste des Transactions</Link></li>
                                    <li className="breadcrumb-item">Nouveau Transaction</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-lg-5">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <h3>Liste des comptes</h3>
                                        <form className="row g-1 mx-auto" encType="multipart/form-data">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <input
                                                        type="text" placeholder="Numero de Compte" name='num_compte'
                                                        className="form-control form-control-sm"
                                                        id="num_compte" aria-label="name" aria-describedby="basic-addon3"
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary form-control-sm"
                                                    >                                                        <span class="material-symbols-outlined">
                                                            search
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <hr />
                                        <DataNearEndDate />
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <h3>
                                            Renouvellements
                                        </h3>
                                        <form className="row g-3 mx-auto" encType="multipart/form-data">
                                            <div className="col-md-12 mb-4">
                                                <div className="input-group">
                                                    <select className="form-select form-select-sm" name='type_transaction' aria-label="type_transaction" id="type_transaction" >
                                                        <option value="">Selectionner la duree</option>
                                                        <option value="truemonth">3 mois</option>
                                                        <option value="sixmonth">6 mois</option>
                                                        <option value="ninemonth">9 mois</option>
                                                        <option value="twelvemonth">12 mois</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Numero Compte</label>
                                                <div className="input-group">
                                                    <input type="text" disabled placeholder="0000-00000-0000" name='new_balance' required className="form-control form-control-sm" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Type Compte</label>
                                                <div className="input-group">
                                                    <input type="text" disabled placeholder="Bousanm" name='new_balance' required className="form-control form-control-sm" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Etat du Compte</label>
                                                <div className="input-group">
                                                    <input type="text" disabled placeholder="Actif" name='new_balance' required className="form-control form-control-sm" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Balance</label>
                                                <div className="input-group">
                                                    <input type="text" disabled placeholder="Balance" name='new_balance' required className="form-control form-control-sm" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Interet</label>
                                                <div className="input-group">
                                                    <input type="text" disabled placeholder="Interet" name='new_balance' required className="form-control form-control-sm" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary" data-mdb-ripple-init>Renouveler</button>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
            <Footer />
        </>
    )
}

export default Renewal
